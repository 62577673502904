import React, { useState, useMemo, useCallback } from "react";
import PageHeader from "../../page_header/PageHeader";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import MyTable from '../../table/Table';
import ButtonsHistory from "./buttons";
import api from "../../../services/api";
import { useUser } from "../../../context/UserContext"

function History (props){
  const [evaluations, setEvaluations] = useState([]);
  const [total, setTotal] = useState(0);
  const [total_filtered, setTotalFiltered] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState();
  const [loading, setLoading] = useState(false);
  const user = useUser();

  const fetchEvaluations = useCallback(({pageIndex, pageSize, searchTerm}) => {
    setLoading(true);
    let expression = `/evaluations?page=${pageIndex+1}&page_size=${pageSize}`;
    if (typeof(searchTerm) !== 'undefined') {
      expression = expression.concat(`&search=${searchTerm}`)
    }
    api.get(expression)
    .then((response) => {
      setEvaluations(response.data)
      setTotal(response.headers['x-total-registries']);
      setTotalFiltered(response.headers['x-total-filtered']);
      setPageCount(Math.ceil(response.headers['x-total-filtered']/pageSize));
      setLoading(false);
    }).catch((error) => {
        console.error(error);
    })
  },[]);

  const columns = useMemo(() => [
  {
    Header: "Estabelecimento",
    Cell: ({row}) => {
      return (row.original.status === "Em andamento")&&(user.role === 1)?
        <Link to = {`/avaliacoes/${row.original.ub_id}`}>{row.original.ub.name}</Link>
      :
       <Link to = {`/evaluation/${row.original.id}`}>{row.original.ub.name}</Link>
    }
  },{
    Header: "Status",
    accessor: "status"
  },{
    Header: "Critérios respondidos",
    accessor: row => row["total_criterions_answered"]
  },{
    Header: "Nota",
    accessor: row => parseFloat(row["total_rate"]).toFixed(2)
  }
],[user.role])

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    fetchEvaluations({pageIndex, pageSize, searchTerm})
  },[fetchEvaluations, pageIndex, pageSize, searchTerm] )

  function handleChange(e){
    e.preventDefault();
    let target = e.target;
    setSearchTerm(target.value)
  }

  return (
  <>
    <PageHeader>
      <Link to= "/" className = "breadcrumb-item"> Home </Link>
      <Breadcrumb.Item active>Histórico </Breadcrumb.Item>
    </PageHeader>
    <Card>
      <Card.Body>
        <Card.Title>Histórico</Card.Title>
        <MyTable
          columns = {columns}
          data = {evaluations}
          fetchData = {fetchEvaluations}
          pageCount = {pageCount}
          total = {total}
          total_filtered = { total_filtered }
          search = {{ placeholder: "Nome",
            handleChange: handleChange,
            handleClick: handleSearch
          }}
          searchTerm = { searchTerm }
          loading = {loading}
          pageIndex = {pageIndex}
          pageSize = {pageSize}
          setPageIndex = {setPageIndex}
          setPageSize = {setPageSize}
        />
        <ButtonsHistory />
      </Card.Body>
    </Card>
  </>
  )
}

export default History;
