import React from "react";
import {Table, Button, ButtonGroup} from "react-bootstrap";
import Rate from 'rc-rate';
import { Link } from "react-router-dom";
import Octicon, {Pencil}  from '@githubprimer/octicons-react';
import Observations from "./observations";
import { useUser } from "../../../context/UserContext";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar} from '@fortawesome/free-solid-svg-icons';

function TableDashBoardEvaluation(props){
const user = useUser();

  function canEvaluate(){
    return props.evaluation && props.evaluation.status === 'Em andamento' && user.role ===1
  };

  function canVisualise(){
    return props.evaluation && props.visualiseSector
  }

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Setor</th>
            <th>Status avaliação</th>
            <th>Critérios Respondidos</th>
            <th>Nota Atual</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {props.evaluation?
            <>{props.evaluation.content ?props.evaluation.content.map((sector, index) => {
              return(
                <tr key = {index}>
                  <td>{sector.type_sector.name}</td>
                  <td>{sector.total_criterions_answered === sector.total_criterions? 'Concluído':'Em andamento'}</td>
                  <td>{sector.total_criterions_answered}/{sector.total_criterions}</td>
                  <td>{parseFloat(sector.total_rate).toFixed(2)}
                    <Rate
                      defaultValue={sector.total_rate}
                      style={{fontSize: 16}}
                      disabled={true}
                      allowHalf
                      character={<FontAwesomeIcon icon = {faStar} size = { "sm" } /> }
                    />
                  </td>
                  <td>
                    <ButtonGroup aria-label="Basic example">
                      {canEvaluate()
                        ?<Link to ={"/newevaluate/" + sector.id}
                         className="btn btn-primary"><Octicon icon={Pencil}/> Avaliar</Link>
                        :<></>
                      }
                      {canVisualise()
                        ?<Button variant = "primary" onClick ={()=>props.visualiseSector(sector.id)}>Visualisar</Button>
                        :<></>
                      }
                      <Observations sector={sector}/>
                    </ButtonGroup>
                  </td>
                </tr>)
            }):<tr>
              <td>
                <div className="col-md-12 mx-auto "><Skeleton /><br/><Skeleton /><br/><Skeleton /><br/><Skeleton /><br/><Skeleton /></div>
              </td>
            </tr>}</>
          : <h4>Sem informações</h4>
          }
        </tbody>
      </Table>
    </>
  )
}



// export function EvaluationTable({
//     columns,
//     data,
//     fetchData,
//     loading,
//     pageCount:controlledPageCount,
//     total,
//     total_filtered,
//     pageIndex,
//     setPageIndex,
//     pageSize,
//     setPageSize,
//    }) {
//   // Use the state and functions returned from useTable to build your UI
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     prepareRow,
//     page,
//     pageCount,
//     flatColumns
//   } = useTable({
//     columns,
//     data,
//     initialState: { pageIndex: 0},
//     manualPagination: true,
//     pageCount: controlledPageCount,
//   },
//   // useSortBy,
//   useExpanded,
//   usePagination,
// )
//
// function gotoPage(page){
//   setPageIndex(page);
// }
// const canPreviousPage =  pageIndex > 0
// const canNextPage = pageIndex < pageCount - 1
//
// function previousPage(){
//   if (canPreviousPage) {
//     setPageIndex(pageIndex - 1)
//   }
// }
// function nextPage(){
//   if (canNextPage){
//     setPageIndex(pageIndex + 1);
//   }
// }
//
// React.useEffect(() => {
//     fetchData({ pageIndex, pageSize})
//   }, [fetchData, pageIndex, pageSize])
//
//   const renderRowSubComponent = React.useCallback(
//     ({ row }) => {
//       return (
//         <ul>
//           {row.original.type_sector.criterions.map((criterion, index) => {
//             return <li>{criterion.name}</li>
//           })}
//         </ul>
//       )},
//     []
//   )
//
//   // Render the UI for your table
//   return (
//     <>
//       <div className="tableWrap">
//         <Table {...getTableProps()} striped bordered hover className = "evaluation_table" size = "sm" responsive>
//           <thead>
//             {headerGroups.map(headerGroup => (
//               <tr {...headerGroup.getHeaderGroupProps()}>
//                 {headerGroup.headers.map(column => (
//                   <th {...column.getHeaderProps()}>
//                     <span>
//                       {column.render('Header')}
//                     </span>
//                     <div>{column.canFilter ? column.render('Filter') : null}</div>
//                   </th>
//                 ))}
//               </tr>
//             ))}
//           </thead>
//           <tbody {...getTableBodyProps()}>
//             {page.map(
//               (row, i) => {
//                 prepareRow(row);
//                 return (
//                   <React.Fragment key = {i}>
//                     <tr {...row.getRowProps()}>
//                       {row.cells.map(cell => {
//                         return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
//                       })}
//                     </tr>
//                   {row.isExpanded ? (
//                     <tr>
//                       <td colSpan={flatColumns.length}>
//                         {/*
//                             Inside it, call our renderRowSubComponent function. In reality,
//                             you could pass whatever you want as props to
//                             a component like this, including the entire
//                             table instance. But for this example, we'll just
//                             pass the row
//                           */}
//                         {renderRowSubComponent({ row })}
//                       </td>
//                     </tr>
//                   ) : null}
//                   </React.Fragment>
//             )}
//           )}
//             <tr>
//               {loading ? (
//                 // Use our custom loading state to show a loading indicator
//                   <td>Loading...</td>
//               ) : (
//                 <td colSpan = {columns.length}>
//                   Exibindo {page.length} de {total_filtered} registros, total: {total}
//                 </td>
//               )}
//             </tr>
//           </tbody>
//         </Table>
//       </div>
//       <Paginator
//         pageCount = { pageCount }
//         pageIndex = { pageIndex }
//         gotoPage = { gotoPage }
//         previous = { previousPage }
//         next = { nextPage }
//         hasPrevious = { canPreviousPage }
//         hasNext = { canNextPage }
//       />
//
//       <Form.Group controlId="formGridState">
//         <Form.Control as="select" onChange={e => {
//           setPageSize(Number(e.target.value))
//         }}>
//           {[10, 20, 30, 40, 50].map(pageSize => (
//             <option key={pageSize} value={pageSize}>
//               Exibir {pageSize} registros por página
//             </option>
//           ))}
//         </Form.Control>
//       </Form.Group>
//     </>
//   )
// }


export default TableDashBoardEvaluation;
