import React from "react";
import Notifications from "./notifications";
import StatusEvaluations from "./statusEvaluations";
import LastEvaluations from "./lastEvaluations";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import { useUser } from "../../context/UserContext";

function DashBoard(props){
  const history = useHistory();
  const user = useUser();

  function handleClick(){
    history.push("/nova_avaliacao")
  }

  return (
    <>
      <Row className = "pb-3">
        <Col>
          <Notifications/>
        </Col>
        <Col>
          <StatusEvaluations/>
        </Col>
      </Row>
      <Row className = "justify-content-right">
        <Col>
        {user.role === 1?
          <Button variant = "primary" onClick = { handleClick } className = "float-right">
          <FontAwesomeIcon icon = {faPlusCircle} size = { "lg" } /> Nova Avaliação
          </Button>
        :""}
        </Col>
      </Row>
      <Row>
        <Col>
          <LastEvaluations/>
        </Col>
      </Row>
    </>
  )
}

export default DashBoard;
