import React from "react";
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import api from "../../services/api";

class StatusEvaluations extends React.Component{
  constructor(props){
    super(props);
    this.state={
        status: {
        count_current: 0,
        count_finish: 0,
        count_cancel: 0
      }
    }
  }
  async getStatusEvaluation() {
    try {
      let response = await api.get(`evaluations/get_count_evaluations`);
      this.setState({status: response.data});
    }catch{}
  }
  componentDidMount(){
    this.getStatusEvaluation();
  }
  render(){
    return (
      <>
      <Card>
        <Card.Header className = "bg-primary text-white" >Status gerais de avaliações</Card.Header>
          <ListGroup>
            <ListGroup.Item>Em andamento
              <span className="badge badge-primary badge-pill float-right">{this.state.status.count_current}</span></ListGroup.Item>
            <ListGroup.Item>Concluídas
              <span className="badge badge-success badge-pill float-right">{this.state.status.count_finish}</span></ListGroup.Item>
            <ListGroup.Item>Canceladas
              <span className="badge badge-danger badge-pill float-right">{this.state.status.count_cancel}</span></ListGroup.Item>
          </ListGroup>
        </Card>
      </>
    )
  }
}

export default StatusEvaluations;
