// TODO: implement SortBy
import React from "react";
import {useTable, usePagination} from "react-table";
import {Table, Button, Form} from "react-bootstrap";
import Paginator from "./Paginator";
import {InputGroup, FormControl } from "react-bootstrap";
import ReactLoading from "react-loading";

function MyTable({
    columns,
    data,
    fetchData,
    loading,
    pageCount:controlledPageCount,
    search,
    total,
    total_filtered,
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    searchTerm }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0},
    manualPagination: true,
    pageCount: controlledPageCount,
  },
  // useSortBy,
  usePagination,
)

function gotoPage(page){
  setPageIndex(page);
}
const canPreviousPage =  pageIndex > 0
const canNextPage = pageIndex < pageCount - 1

function previousPage(){
  if (canPreviousPage) {
    setPageIndex(pageIndex - 1)
  }
}
function nextPage(){
  if (canNextPage){
    setPageIndex(pageIndex + 1);
  }
}

React.useEffect(() => {
    fetchData({ pageIndex, pageSize, searchTerm})
  }, [fetchData, pageIndex, pageSize, searchTerm])

  // Render the UI for your table
  return (
    <>
      <InputGroup className="mb-3">
        <FormControl
          placeholder={search.placeholder}
          onChange = {search.handleChange}
        />
        <InputGroup.Append>
          <Button
            variant="outline-primary"
            onClick = {search.handleClick}
          >Busca</Button>
        </InputGroup.Append>
      </InputGroup>

      <Table {...getTableProps()} striped bordered hover size = "sm" responsive>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  <span>
                    {column.render('Header')}
                  </span>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )}
          )}
          <tr>
            {loading ? (
              // Use our custom loading state to show a loading indicator
              <td colSpan = {columns.length} align= "center">
                <ReactLoading type = "bars" color = "black"/>
              </td>
            ) : (
              <td colSpan = {columns.length}>
                Exibindo {page.length} de {total_filtered} registros, total: {total}
              </td>
            )}
          </tr>
        </tbody>
      </Table>
      <Paginator
        pageCount = { pageCount }
        pageIndex = { pageIndex }
        gotoPage = { gotoPage }
        previous = { previousPage }
        next = { nextPage }
        hasPrevious = { canPreviousPage }
        hasNext = { canNextPage }
      />

      <Form.Group controlId="formGridState">
        <Form.Control as="select" onChange={e => {
          setPageSize(Number(e.target.value))
        }}>
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Exibir {pageSize} registros por página
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    </>
  )
}

export default MyTable;
