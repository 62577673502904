import React, {useState} from "react";
import api from "../services/api";
import Loading from "../components/loading/Loading";

const AuthContext = React.createContext();
const TOKEN_KEY = "@avaliacaoUBS-Token";

function AuthProvider(props){
  const [data, setData]  = useState( {user: null});
  const [isLoading, setIsLoading] = useState(true);

  const login = async (response) => {
    if (response.headers.authorization) {
      localStorage.setItem(TOKEN_KEY, response.headers.authorization);
      getUser();
    }
  } // make a login request
  const register = () => {} // register the user

  const logout = async () => {
    await api.delete("/logout")
      .then((response) => {
        localStorage.removeItem(TOKEN_KEY);
        window.location.reload();
      }).catch((error) => {
        console.error(error);
      })
  } // clear the token in localStorage and the user data

  async function getUser(){
      const token = getToken();
      if (token) {
        await api.get("/get_current_user")
        .then((response) => {
          setData({user: response.data });
          setIsLoading (false);
        }).catch((error) => {
          localStorage.removeItem(TOKEN_KEY);
          setIsLoading(false);
          return null;
        })
      }else {
        setIsLoading(false);
        return null;
      }

  }

  React.useLayoutEffect(() => {
    getUser();
  },[])

if (isLoading) {
  return <Loading/>
  }else {
    return (
      <AuthContext.Provider value = {{data, login, logout, register}} {...props} />
    )
  }
}


function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

function getToken(){
  return localStorage.getItem(TOKEN_KEY);
}

export { AuthProvider, useAuth, AuthContext, getToken }
