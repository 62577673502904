
import React, { useState, useEffect }  from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import api from "../../services/api";
import { useParams, useHistory } from "react-router-dom";
import {Card} from "react-bootstrap";
import  MyToast from "../../services/toast";

function EditNotices({fetchNotices, pageIndex, pageSize, searchTerm}) {
    const {id} = useParams();
    const history = useHistory();
    const [isValidated, setIsValidated] = useState(false);
    const [note, setNote] = useState('');
    const [regional_id, setRegionalId] = useState('');
    const [type_notice, setTypeNotice] = useState('');
    const [status, setStatus] = useState('');
    const [regional, setRegionals] = useState([]);
    const handleSubmit = async event => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }else {
            await api.put(
                `/notices/${id}`,
                {
                    notice: { notes: note, status: status, regional: regional_id, type_notice: type_notice}
                },
                {headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }}
            ).then(function (response){
                MyToast.infoToast('Noticia editada com sucesso!',5000, "top-center");
                back();
            }).catch(function (error) {
                MyToast.errorToast('Erro ao editar noticia: '+ error.message,5000, "top-center");
                console.error(error);
            })
        }
        setIsValidated(true);
    };
    const getEstablishmentFilter = ((e)=>{
        setRegionalId(Number(e.target.value));
    });
    const setTypeMessage = ((e)=>{
        setTypeNotice(Number(e.target.value));
    });
    const handleChange = () => {
        setStatus(!status)
    };
    const fetchRegionals = async() =>{
        await api.get(`/regionals`)
            .then((response) => {
                setRegionals(response.data)
            }).catch((error) => {
                console.error(error);
            });
    };
    function back(){
        history.goBack();
    }
    useEffect(() => {
        fetchRegionals();
        api.get(`/notices/${id}`)
            .then((response) => {
                setRegionalId(response.data.regional);
                setStatus(response.data.status);
                setNote(response.data.notes);
            }).catch((error) => {
            console.error(error);
        })
    },[id]);
    return (
        <>
        <Card>
          <Card.Body>
            <Card.Title>Tipo de setor</Card.Title>
            <Form id = "form_notices" noValidate validated= {isValidated} onSubmit = {handleSubmit}>
              <Form.Group>
                <Form.Control
                    required
                    type="text"
                    placeholder="Notícia ou aviso..."
                    value={note || ''}
                    onChange={e => setNote(e.target.value)}
                    maxLength="300"
                    minLength="5"
                />
                  <Form.Text className="text-muted">
                      Escreva a noticia ou aviso a ser mandado para os usuários, mínimo 5 e máximo 300 caracteres.
                  </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Control
                    as = "select"
                    value={regional_id}
                    onChange={getEstablishmentFilter}
                >
                  <option value = ''  >Selecione a regional</option>
                    <option value = {10}  >Todos</option>
                    {regional.map((regional, index) => {
                        return <option key = {regional.id} value={regional.id}>{regional.name}</option>
                    })}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Control
                    as = "select"
                    value={type_notice}
                    onChange={setTypeMessage}
                >
                  <option value = ''  >Tipo de noticia/aviso</option>
                  <option value = {0}  >Alerta</option>
                  <option value = {1}  >Importante</option>
                  <option value = {2}  >Aviso</option>

                </Form.Control>
              </Form.Group>
              <Form.Check
                  label="Ativo?"
                  value={status}
                  checked={status}
                  onChange={handleChange}
              />
              <Button variant="secondary" onClick={back}>
                Cancelar
              </Button>
              <Button variant="success" type="submit">
                Salvar
              </Button>
            </Form>
          </Card.Body>
        </Card>
        </>

    );
}

export default EditNotices;
