import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Navbar from "../navigation/Navbar";
import Sidebar from "../navigation/Sidebar";
import DashBoard from "../dashboard/DashBoard";
import ShowEvaluation from "../evaluations/history/ShowEvaluation";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Criterions from "../criterions/Criterions";
import Questions from "../questions/Questions";
import Users from "../users/Users";
import TypeSectors from "../type_sectors/TypesSectors";
import EditTypeSectors from "../type_sectors/EditTypeSectors";
import Ubs from "../ubs/Ubs";
import History from "../evaluations/history/History";
import EditNotices from "../notices/EditNotices";
import Notices from "../notices/Notices";

function Admin(props){
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  function toggleSidebar(){
    setIsSidebarOpen(!isSidebarOpen);
  };

  function handleStateChange (state) {
    setIsSidebarOpen(state.isOpen)
  }

  function closeMenu(){
    setIsSidebarOpen(false)
  }

  return (
    <Router>
      <div className = "wrapper">
      <Sidebar isOpen = {isSidebarOpen} handleStateChange = {handleStateChange} closeMenu = {closeMenu}/>
        <div id = "content" >
          <Navbar toggleSidebar = {toggleSidebar} />
          {/* TODO: add Messages */}
          <Container fluid = {true}>
            <main>
              <Row className = "p-3">
              </Row>
              <Switch>
                {/* Home */}
                <Route exact path="/" component = { DashBoard } />
                {/* Users CRUD*/}
                <Route path="/usuarios" exact> <Users /> </Route>
                {/* UBS list, for setting sectors */}
                <Route path="/ubs" exact component = { Ubs } />
                {/* TypeSectors CRUD */}
                <Route path="/tiposDeSetor" exact> <TypeSectors /> </Route>
                {/* Edit TypeSectors */}
                <Route path="/tiposDeSetor/:id" exact component = { EditTypeSectors } />
                {/* Notices CRUD */}
                <Route path="/notices" exact> <Notices/> </Route>
                {/* Edit Notices */}
                <Route path="/notices/:id" exact component = { EditNotices } />
                {/* criterions CRUD*/}
                <Route path="/criterios" exact> <Criterions /> </Route>
                {/* questions crud */}
                <Route path="/questions" exact > <Questions/> </Route>
                {/* evaluations list */}
                <Route path="/historico" exact> <History/> </Route>
                {/* Show Evaluations*/}
                <Route path="/evaluation/:id" exact component={ ShowEvaluation }/>
                {/* */}
                <Route path="*" exact> <Redirect to = "/"/> </Route>
              </Switch>
            </main>
          </Container>
        </div>
      </div>
    </Router>
  )
}

export default Admin;
