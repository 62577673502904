import React, {useState, useEffect, useCallback} from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Octicon, {Note} from '@githubprimer/octicons-react';
import api from "../../../services/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import {Table} from "react-bootstrap";
import { useUser } from "../../../context/UserContext";

function NewObservation(props) {
    const [title, setTitle] = useState('');
    const [show, setShow] = useState('');
    const [observation_id, setObervationId] = useState(-1);
    const [observations, setObservations] = useState([]);
    const user = useUser();

    const handleSubmit = (async (e) => {
      e.preventDefault();
        let obs = {
            observation: {
                note: title,
                sector_id: props.sector.id,
                user_id: user.id
            }
        };
        await api.post(`/observations`, obs);
        getObservations();
        setShow(false);
        setTitle('');
    });
    const handleSubmitEdit = (async (e) => {
      e.preventDefault();
        let obs = {
            observation: {
                note: title,
                sector_id: props.sector.id,
                user_id: user.id
            }
        };
        await api.put(`/observations/${observation_id}`, obs);
        getObservations();
        setShow(false);
        setTitle('');
    });
    //chama endpoint observation do rails : DELETE
    const deleteObservation = (async (obs) => {
        if (window.confirm(`Você tem certeza que deseja deletar a observação?`)) {
            await api.delete(`/observations/${obs.id}`);
            getObservations();
        }
    });
    const handleEdit = (async (obs) => {
        setObervationId(obs.id);
        setTitle(obs.note)
    });
    const getObservations = useCallback(async () => {
        let obs = [];
        if (props.sector.id) {
            let response = await api.get(`/observations?sector=` + props.sector.id);
            obs = await response.data;
            setObservations(obs);
        }
    },[props.sector.id]);

    useEffect(() => {
      getObservations();
    }, [props.sector, getObservations]);
    return (
        <div>
            <Button variant="info" onClick={e => setShow(true)}><Octicon icon={Note}/> Observações</Button>

            <Modal show={show || false} onHide={e => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Nova observação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" placeholder="Escreva suas obs. sobre o setor..." value={title || ''}
                                  onChange={e => setTitle(e.target.value)}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => setShow(false)}>
                        Cancelar
                    </Button>
                    {observation_id < 0
                        ? <form onSubmit={handleSubmit}>
                            <Button variant="success" type="submit">
                                Salvar
                            </Button>
                        </form>
                        : <form onSubmit={handleSubmitEdit}>
                            <Button variant="success" type="submit">
                                Salvar
                            </Button>
                        </form>
                    }
                </Modal.Footer>
                <div>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Observação</th>
                            <th>Ações</th>
                        </tr>
                        </thead>
                        <tbody>
                        {observations.length > 0 ? observations.map((obs, index) => {
                            return <tr key = {obs.id}>
                                <td>{obs.note}</td>
                                <td>{obs.user_id === user.id ?
                                    <div><Button className="badge badge-primary" onClick={e => handleEdit(obs)}>
                                        <FontAwesomeIcon icon="pencil-alt" size="lg"/></Button>
                                        <Button className="badge badge-danger"
                                                onClick={e => deleteObservation(obs)}><FontAwesomeIcon
                                            icon="trash-alt" size="lg"/></Button>
                                    </div>
                                    : '--'}</td>
                            </tr>
                        }) : <tr><td colSpan = {2}></td></tr>}
                        </tbody>
                    </Table>
                </div>
            </Modal>
        </div>
    );
}

export default NewObservation;
