import React, { useState, useEffect, useCallback } from "react";
import {Badge, Card} from "react-bootstrap";
import Col from 'react-bootstrap/Col'
import api from "../../../services/api";
import {
    Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer
} from 'recharts';
import dateFormat from 'dateformat';
import Skeleton from 'react-loading-skeleton';

function StatusEvaluation(props) {
  const [data, setData] = useState([]);
  const [user, setUser] = useState({});

  const getSectors = useCallback(()=> new Promise((resolve, reject) => {
    let d = [];
    if (props.evaluation && props.evaluation.content) {
      for (let i of props.evaluation.content) {
        d.push({
            subject: i.type_sector.name, A: Number(i.total_rate), fullMark: 5,
        })
      }
    }
    resolve(d)
  }),[props.evaluation]);

  const handleRadar = useCallback(()=> {
     getSectors().then(
      (response)=>{
        setData(response);
      });
  },[getSectors]);

  const getUser = (id)=> {
      api.get(`/users/${id}`)
      .then((response) => {
        setUser(response.data);
      }).catch((error) => {
        setUser({});
        console.error(error);
      })
  };

  useEffect(() => {
    handleRadar();
    getUser(props.evaluation.user_id)
  },[props.evaluation, handleRadar]);

  return (
    <>
      <Col md={6}>
        <StatusCard evaluation = {props.evaluation} user = { user }/>
      </Col>
      <Col md = {6}>
        <GraphCard evaluation = {props.evaluation} data = {data}/>
      </Col>
    </>
  )
}

function StatusCard({evaluation, user}){

  //define the badge variant based on evaluation status
  const statusBadgeVariant = ()=>{
    switch(evaluation.status) {
      case 'Concluído':
        return 'success';
      case 'Em andamento':
        return 'secondary';
      case 'Cancelado':
        return 'danger';
      default:
        return 'secondary';
    }
  };

  function getLastEdit(){
    let lastEdit = "";
    if(typeof evaluation.users !=='undefined' && evaluation.users.length>0){
      lastEdit = evaluation.users.reduce((max, edit) => max.updated_at > edit.updated_at ? max : edit);
    }
    return lastEdit;
  }

  return(
    <Card className = "m-1">{evaluation?
      <> {evaluation.content ?
        <div>
          <Card.Header>Status avaliação: <Badge variant={statusBadgeVariant()} className="float-right">{evaluation.status}</Badge></Card.Header>
          <Card.Body>
            <Card.Text>
              Critérios Avaliados: <span
                                   className="float-right">{evaluation.total_criterions_answered}/{evaluation.total_criterions}
                ({evaluation.content ? parseFloat(evaluation.total_criterions_answered / (evaluation.total_criterions||1) * 100).toFixed(2) + '%' : 0 + '%'})</span>
            </Card.Text>
            <Card.Text>
              Data da Avaliação: <span className="float-right">{dateFormat(evaluation.updated_at,'dd/mm/yyyy')}</span>
            </Card.Text>
            <Card.Text>
              Nota Geral: <span className="float-right">{evaluation.content ? parseFloat(evaluation.total_rate).toFixed(2) : 0}</span>
            </Card.Text>
            <Card.Text>
              Criado por: <span className="float-right">{user? user.name : '--'}</span>
            </Card.Text>
            <Card.Text>
              Editado por: <span className="float-right">{getLastEdit()? getLastEdit().name : '--'}</span>
            </Card.Text>
          </Card.Body>
        </div>
      :
      <div className="col-md-12 mx-auto ">
        <Skeleton /><br/><Skeleton /><br/><Skeleton /><br/><Skeleton /><br/><Skeleton />
      </div>}
      </>
    :<div className="col-md-12 mx-auto "><h4><strong>Não foi identificado avaliações para este estabelecimento!</strong></h4></div>}
    </Card>
  )
}

function GraphCard({evaluation, data}){
  return(
    <Card className = "m-1">
      <Card.Body>
        {evaluation?
          <>{evaluation.content ?
            <ResponsiveContainer width="100%" aspect = {2}>
              <RadarChart
                data={data}
                margin = {{top: 20, right: 20, bottom: 20, left: 20 }}
              >
                <PolarGrid/>
                <PolarAngleAxis dataKey="subject"/>
                <PolarRadiusAxis angle = {30} domain= {[0,5]}/>
                <Radar
                  name="Sectors"
                  dataKey="A"
                  stroke="#8884d8"
                  fill="#8884d8"
                  fillOpacity={0.6}
                />
              </RadarChart>
            </ResponsiveContainer>
          :<div><Skeleton /><br/><Skeleton /><br/><Skeleton /><br/><Skeleton /><br/><Skeleton /></div>
          }</>
        : <h4><strong>Dados insuficientes para gerar o gráfico...</strong></h4>
        }
      </Card.Body>
    </Card>
  );
}

export default StatusEvaluation;
