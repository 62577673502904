import React from "react";
import api from "../../services/api";
import { UserContext } from "../../context/UserContext"
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import Badge from "react-bootstrap/esm/Badge";
class Notifications extends React.Component{
  static contextType = UserContext;
  constructor(props){
    super(props);
    this.state={
      notices:[]
    }
  }
  async getNotices() {
    try {
      let response = await api.get(`get_notices?regional=`+ this.context.regional_id);
      this.setState({notices: response.data});
    }catch{}
  }
  componentDidMount(){
    this.getNotices();
  }
  render(){
    return (
      <>
      <Card>
        <Card.Header className = "bg-primary text-white" >Notificações e avisos</Card.Header>
          <ListGroup className="list-group-flush">
          {
            this.state.notices.length>0? this.state.notices.map((notice, index) => {
                return <ListGroup.Item key={index}>{notice.notes}{notice.type_notice === 0 ? <Badge pill variant="warning" className="float-right">Alerta</Badge> :
                    notice.type_notice === 1 ? <Badge pill variant="info" className="float-right">Importante</Badge> :
                        <Badge pill variant="primary" className="float-right">Aviso</Badge>}
                        </ListGroup.Item>
            }):<ListGroup.Item>Não há notificações ou avisos</ListGroup.Item>
          }
          </ListGroup>
        </Card>
      </>
    )
  }
}

export default Notifications;
