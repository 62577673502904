import React, { useState, useEffect, useCallback } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import api from "../../../services/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import  MyToast from "../../../services/toast";
function EditQuestions(props) {
    const [question, setQuestion] = useState();
    const [criterions, setCriterions] = useState([]);
    const [typequestion, setTypeQuestion] = useState();
    const [type_sectors, setTypeSectors] = useState([]);
    const [active, setActive] = useState();
    const [criterion_id, setCriterionId] = useState();
    const [show, setShow] = useState('');

    const resetForm = useCallback(() => {
      setQuestion(props.question.question);
      setTypeQuestion(props.question.type_question);
      setActive(props.question.active);
      setCriterionId(props.question.criterion_id);
      setShow(false);
    },[props.question]);

    const handleSubmit = (async (e) => {
      e.preventDefault()
      var quest = {
          question: {
              question: question || props.question.question,
              active: active,
              type_question: typequestion,
              criterion_id: criterion_id
          }
      };
      await api.put(`/questions/${props.question.id}`, quest).then(()=>{
          MyToast.infoToast('Pergunta editada com sucesso!',5000, "top-center");
          setShow(false);
          setQuestion('');
          props.fetchQuestions(props.pageIndex, props.pageSize, props.searchTerm);
      }).catch((error)=>{
          MyToast.errorToast('Erro ao editar a pergunta: '+ error.message,5000, "top-center");
      });
    });
    const getCriterions = (async ()=>{
        let response = await api.get(`/criterions`);
        setCriterions(response.data);
    });
    const getCriterionsFilter = (async (type_sector)=>{
        let response = await api.get(`/criterions?type_sector=`+type_sector);
        setCriterions(response.data);
    });
    const getTypeSectors = (async ()=>{
        let response = await api.get(`/type_sectors`);
        setTypeSectors(response.data);
    });

    useEffect(() => {
      getCriterions();
      getTypeSectors();
      resetForm();
    },[resetForm]);
    const handleChange = () => {
        setActive(!active)
    };
    return (
        <>
            <Button variant = "warning" size = "sm">
                <FontAwesomeIcon icon="pencil-alt" onClick={e => setShow(true)} size="lg"/>
            </Button>
            <Modal show={show || false} onHide={e => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Pergunta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Control as="textarea" type="text" placeholder="Pergunta..." value={question || ''} onChange={e => setQuestion(e.target.value)} maxLength="300" minLength="5"/>
                        <Form.Text className="text-muted">
                            Escreva a pergunta, mínimo 5 e máximo 300 caracteres.
                        </Form.Text>
                    </Form.Group>
                    <div className = "form-group form-label-group">
                        <select className="custom-select"  onChange={e => getCriterionsFilter(e.target.value)}>
                            <option>Selecione o Tipo de setor</option>
                            {type_sectors.map((type_sector, index) => {
                                return <option key = { type_sector.id } value={type_sector.id}>{type_sector.name}</option>
                            })}
                        </select>
                        <select className="custom-select" value={criterion_id || props.question.criterion_id} onChange={e => setCriterionId(e.target.value)}>
                            <option>Selecione o critério</option>
                            {criterions.map((criterion, index) => {
                                return <option key = { criterion.id } value={criterion.id}>{criterion.name}</option>
                            })}
                        </select>
                        <select className="custom-select" value={typequestion || props.question.type_question} onChange={e => setTypeQuestion(e.target.value)}>
                            <option>Selecione o tipo de pergunta</option>
                            <option value="1">Pontuação 1 a 5</option>
                            <option value="2">Opções de escolha (não, talvez, sim)</option>
                            <option value="3">Sim ou não</option>
                        </select>
                    </div>
                    <Form.Check label="Ativo?" value={active}
                                checked={active}
                                onChange={handleChange}/>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={resetForm}>
                        Cancelar
                    </Button>
                    <form onSubmit={handleSubmit}>
                        <Button variant="success" type="submit">
                            Salvar
                        </Button>
                    </form>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditQuestions;
