import React from "react";
import { Link, useHistory } from "react-router-dom";
import Octicon, {Reply, Check} from '@githubprimer/octicons-react';
import NewObservation from "./newObservation";
import api from "../../../services/api";
import  MyToast from "../../../services/toast";

function ButtonsNewEvaluation(props){
  let history = useHistory();

  function handleSave(){
    let promise
    if (props.evaluation.id) {
      promise = api.put(`/evaluations/${props.evaluation.id}`, {evaluation: props.evaluation})
    }else {
      promise = api.post("/evaluations", {evaluation: props.evaluation})
    }
    promise.then((response) => {
      MyToast.successToast('Avaliação salva com sucesso!',5000, "top-center");
      localStorage.setItem('evaluation', JSON.stringify(response.data));
      history.push(`/avaliacoes/${props.evaluation.ub_id}`)
    }).catch((error) => {
        MyToast.errorToast('Erro ao salvar avaliação: '+ error.message,5000, "top-center");
        localStorage.setItem('evaluation', JSON.stringify(props.evaluation));
      console.log("Não foi possivel salvar a avaliação no Banco");
      console.error(error);
    })
  };

  return (
    <div className = "col-lg-12">
      <div className = "float-left">
        <Link className="btn btn-outline-primary" to={`/avaliacoes/${props.ubs.id}`}><Octicon icon={Reply} /> Voltar</Link>
        <button className="btn btn-success" onClick={handleSave}><Octicon icon={Check} /> Salvar</button>
      </div>
      <div className = "float-right">
          <NewObservation sector={props.sector}/>
      </div>
      <br/>
    </div>

  )
}

export default ButtonsNewEvaluation;
