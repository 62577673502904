import React from "react";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars} from '@fortawesome/free-solid-svg-icons';
import { useUser } from "../../context/UserContext";

function MyNavbar(props){
  const user = useUser();
  return(
    <>

        <Row noGutters className="bg-dark align-items-center justify-content-center align-middle p-2 shadow">
          <Col>
            <Button
              variant = 'secondary'
              size = 'sm'
              type = 'button'
              className = "toggleButton"
              onClick = {props.toggleSidebar}
            >
              <FontAwesomeIcon icon = {faBars} size = "lg" />
            </Button>
          </Col>
          <Col className = "text-center text-white">
            <span>SAAB</span>
          </Col>
          <Col className = "text-right text-white">
            {user
              ?<span>{user.name}</span>
              : ""
            }
          </Col>
        </Row>






    </>
  );
};
export default MyNavbar;
