import React from "react";
import Form from 'react-bootstrap/Form';
import Select from 'react-select';

function ListUBS(props){
    const options = props.ubs_list.map((ubs)=> {return {key: ubs.id, value: ubs.id, label: ubs.name}});
    return(
        <Form.Group>
            <Select
                placeholder = "Selecione a UBS"
                onChange={e => props.handleChange(e)}
                //defaultValue = {selected_options}
                options = {options}
            />

        </Form.Group>
    );
}

export default ListUBS;
