import React, {useState} from "react";
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import PageHeader from "../page_header/PageHeader";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import api from "../../services/api";
import MyTable from "../table/Table";
import UBSRowActions from "./UBSRowActions";

function Ubs(props){
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [total_filtered, setTotalFiltered] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const fetchIdRef = React.useRef(0);
  const [type_sectors, setTypeSectors] = useState([]);


  // fetch type_sectors
  React.useEffect(()=>{
     api.get("/type_sectors")
    .then(function(response){
      setTypeSectors(response.data)
    }).catch(function(error){
      console.log(`error: ${error}`);
    })
    .finally(function(){

    });
  },[]);

  const fetchData = React.useCallback(({ pageSize, pageIndex, searchTerm }) => {
    // Give this fetch an ID
    const fetchID = ++fetchIdRef.current
    // Set the loading state
    setLoading(true)
      // Only update the data if this is the latest fetch
    if (fetchID === fetchIdRef.current) {
      if (pageIndex>pageSize|| pageIndex < 0){
        console.log("entrou");
        setPageIndex(1);
      }
      let expression = `/ubs?page=${pageIndex+1}&page_size=${pageSize}`;
      if (typeof(searchTerm) !== 'undefined') {
        expression = expression.concat(`&search=${searchTerm}`)
      }
      api.get(expression)
        .then(function (response) {
          setData(response.data);
          setTotal(response.headers['x-total-registries']);
          setTotalFiltered(response.headers['x-total-filtered']);
          setPageCount(Math.ceil(response.headers['x-total-filtered']/pageSize));
          setLoading(false);
        })
        .catch(function (error) {
          // handle error
          console.error(error);
        })
        .finally(function () {
          // always executed
        });
    }
  }, []);

  const columns = React.useMemo(
    () => [{
            Header: 'Tipo',
            accessor: 'type_ub',
          },
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'CNES',
            accessor: 'cnes',
          },
          {
            Header: 'Zona',
            accessor: 'zona',
          },
          {
            Header: 'Setores',
            Cell: ({row})=><UBSRowActions ubs = {row.original} type_sectors = {type_sectors}/>,
          }
        ],[type_sectors]
  );

  function handleChange(e){
    e.preventDefault();
    let target = e.target;
    setSearchTerm(target.value);
  }

  function handleSearch(e){
    e.preventDefault();
    fetchData({ pageIndex, pageSize, searchTerm })
  }

  return(
    <>
      <PageHeader >
        <Link to= "/" className = "breadcrumb-item"> Home </Link>
        <Breadcrumb.Item active>UBS</Breadcrumb.Item>
      </PageHeader>
      <Card className = "mb-3 ">
        <Card.Body>
          <Card.Title>UBS </Card.Title>
          <MyTable
            columns={columns}
            data = {data}
            fetchData = {fetchData}
            loading = {loading}
            pageCount = {pageCount}
            total = {total}
            total_filtered = {total_filtered}
            pageIndex = { pageIndex}
            setPageIndex = { setPageIndex }
            pageSize = { pageSize }
            setPageSize = { setPageSize }
            search = {{ placeholder: "Nome ou CNES",
                        handleChange: handleChange,
                        handleClick: handleSearch
                      }}
            searchTerm = { searchTerm }
          />
        </Card.Body>
      </Card>
    </>
  )
};

export default Ubs;
