// TODO: Display success and errors messages
import React, { useMemo, useCallback } from 'react';
import { Link } from "react-router-dom";
import api from "../../services/api";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MyTable from "../table/Table";

function List(props){
  //chama endpoint typeSector do rails : DELETE


  const columns = useMemo(
    ()=>[{
      Header: 'Noticias/Avisos',
      accessor: 'notes'
         },
        {
            Header: 'Tipo',
            accessor: 'type_notice',
            Cell: ({row}) => {
                switch (row.original.type_notice) {
                    case 0:
                        return 'Alerta'
                    case 1:
                        return 'Importante'
                    case 2:
                        return 'Aviso'
                    default:
                        return ''
                }
            }
        },
        {
            Header: 'Regional',
            accessor: 'regional',
            Cell: ({row}) => {
            switch (row.original.regional){
                case 1:
                    return 'Sul'
                case 2:
                    return 'Sudeste'
                case 3:
                    return 'Leste'
                case 4:
                    return 'Centro/Norte'
                case 10:
                    return 'Todos'
                default:
                    return ''
            }
            }
        },
        {
            Header: 'Ativo',
            accessor: 'status',
            Cell: ({row}) => {
            return row.original.status? "Sim":"Não"
    }
        },
    {
      Header: "Ações",
      Cell: ({row})=>(
        <ButtonGroup className="mr-2">

          <Link to = {`/notices/${row.original.id}` } className = "btn btn-sm btn-warning">
            <FontAwesomeIcon icon="pencil-alt" size = "lg"/>
          </Link>

          <DeleteButton
          notice = { row.original }
          pageIndex = { props.pageIndex }
          pageSize = { props.pageSize }
          searchTerm = { props.searchTerm }
          fetchNotices = { props.fetchNotices }
          />
        </ButtonGroup>
      )
    }],[props.fetchNotices, props.searchTerm, props.pageSize, props.pageIndex]
  );

  return (
    <>
      <MyTable
        columns={columns}
        data = {props.notices}
        fetchData = {props.fetchNotices}
        loading = {props.loading}
        pageCount = {props.pageCount}
        search = {{ placeholder: "Aviso...",
          handleChange: props.handleChange,
          handleClick: props.handleSearch
        }}
        searchTerm = { props.searchTerm }
        pageIndex = {props.pageIndex}
        pageSize = {props.pageSize}
        setPageIndex = {props.setPageIndex}
        setPageSize = {props.setPageSize}
        total = {props.total}
        total_filtered = {props.total_filtered}
      />
    </>
  )
}

function DeleteButton({notice, fetchNotices, pageIndex, pageSize, searchTerm }){

  const deleteNotice = useCallback(() => {
    console.log(fetchNotices, pageIndex, pageSize, searchTerm);
      if (window.confirm(`Você quer realmente deletar o aviso?`)) {
        api.delete(`/notices/${notice.id}`)
        .then((response) => {
          fetchNotices({pageIndex, pageSize, searchTerm});
        }).catch((error) => {
          console.error(error);
          alert(`Não foi possivel remover o registro. ${error.response.data}`)

        })
      }
  },[fetchNotices, pageIndex, pageSize, searchTerm, notice])

  return(
    <Button variant = "danger" onClick={deleteNotice} size = "sm">
      <FontAwesomeIcon icon="trash-alt" size="lg"/>
    </Button>
  );
}

export default List;
