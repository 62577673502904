import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import api from "../../../services/api";
import  MyToast from "../../../services/toast";

function CreateUsers(props) {
    const pageIndex = props.pageIndex;
    const pageSize = props.pageSize;
    const fetchUsers = props.fetchUsers;
    const [isValidated, setIsValidated] = useState(false);
    const [name, setName] = useState(props.user.name ||'');
    const [registration, setRegistration] = useState(props.user.registration||'');
    const [role, setRole] = useState(props.user.role||null);
    const [email, setEmail] = useState(props.user.email||'');
    const [password, setPassword] = useState(null);
    const [password_confirmation, setPassword_confirmation] = useState(null);
    const [active, setActive] = useState(props.user.active||'');
    const [regional_id, setRegionalId] = useState(props.user.regional_id||'');
    const [establishment_id, setEstablishmentId] = useState(props.user.establishment_id||'');
    const [show, setShow] = useState('');
    const [establishment_list, setEstablishmentList] = useState([]);

    const handleSubmit = (async (e) => {
      e.preventDefault();
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
        setIsValidated(true);
      }else {
        var new_user = {
            user: {
                name: name,
                active: active,
                registration: registration,
                role: role,
                email: email,
                regional_id: regional_id,
                ub_id: establishment_id,
                password_confirmation: password_confirmation,
                password: password
            }
        };
        await api.post(`/users`, new_user)
        .then((response) => {
            MyToast.successToast('Usuário cadastrado com sucesso',5000, "top-center");

          fetchUsers({pageIndex, pageSize})
          clearForm();
        }).catch((error) => {
            MyToast.errorToast('Erro ao cadastrar o usuário: '+ error.message,5000, "top-center");
          console.error(error);
          setIsValidated(true);
        });
      }
    });

    useEffect(() => {
      setEstablishmentList(props.establishments)
    },[props.establishments]);

    function clearForm(){
      setShow(false);
      setName('');
      setEmail('');
      setRole('');
      setActive(false);
      setPassword('');
      setPassword_confirmation('');
      setRegionalId('');
      setRegistration('');
      setEstablishmentId('');
      setEstablishmentList(props.establishments.slice())
      setIsValidated(false);
      setShow(false)
    }

    const getFunctionary = (async (registration)=> {
        if (registration !== '' && registration.length === 5){
          try {
            let response = await api.get(`/functionaries/` + registration);
            setRegistration(registration);
            if (response.data[0]) {
                setName(response.data[0].people.name);
            }
          } catch (error) {
            
          }
        }else{
            setRegistration(registration);
            setName('');
        }
    });

    const getEstablishmentFilter = ((e)=>{
      let selected_regional_id = Number(e.target.value);
      setRegionalId(selected_regional_id||null);
      if (Number.isNaN(selected_regional_id)) {
        setEstablishmentList(props.establishments.slice());
      }else {
        setEstablishmentList(props.establishments.filter((ubs) => {
          return ubs.regional_id === selected_regional_id
        }))
      }
    });

    const handleChange = () => {
        setActive(!active)
    }

    function handleSelectRole(e){
      setRole(e.target.value||null)
      setRegionalId('');
      setEstablishmentId('');
    }
    return (
        <>
          <Button onClick={e => setShow(true)} variant="primary" className="float-right create_task_btn">Cadastrar novo usuário</Button>
          <Modal show={show || false} onHide={e => {setShow(false); clearForm()}}>
            <Modal.Header closeButton>
              <Modal.Title>Novo Usuário</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form id = "user_form" noValidate validated={isValidated} onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Control
                    required
                    pattern = "\d\d\d\d\d"
                    type="text"
                    placeholder="Matricula..."
                    defaultValue={registration}
                    onChange={e => getFunctionary(e.target.value)}
                  />
                  <Form.Control.Feedback>certo!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Por favor, preencha a matrícula do usuário ( 5 digitos).
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Nome..."
                    defaultValue={name}
                    onChange={e => setName(e.target.value)}
                  />
                  <Form.Control.Feedback>certo!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Por favor, informe o nome do usuário.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Email..."
                    defaultValue={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <Form.Control.Feedback>certo!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Por favor, informe o email do usuário.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    required
                    as = "select"
                    className="custom-select"
                    value={ role || ''}
                    onChange={handleSelectRole}
                  >
                    <option value = ''>Selecione o papel no sistema</option>
                    <option value={0}>Administrador</option>
                    <option value={1}>Avaliador</option>
                    <option value={2}>Coordenador</option>
                    <option value={3}>Visualizador</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    as = "select"
                    className="custom-select"
                    disabled = {!role || role ==="0"}
                    required = {role && role !== "0"}
                    value={regional_id }
                    onChange={getEstablishmentFilter}
                  >
                    <option value = ''  >Selecione a regional</option>
                    {props.regionals.map((regional, index) => {
                      return <option key = {regional.id} value={regional.id}>{regional.name}</option>
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    as = "select"
                    className="custom-select"
                    disabled = {!regional_id || !(role === '3')}
                    required = {role === '3'}
                    value={establishment_id }
                    onChange={e => setEstablishmentId(e.target.value || null)}
                  >
                    <option value = ''>Selecione o estabelecimento</option>
                    {establishment_list.map((establishment, index) => {
                      return <option key = {establishment.id} value={establishment.id}>{establishment.name}</option>
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Check label="Ativo?"
                    value={active}
                    checked={active}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    required
                    title="A senha tem que conter 6 caracteres e letras minusculas, maiusculas e números."
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                    type="password"
                    placeholder="Senha..."
                    defaultValue={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                    <Form.Text className="text-muted">
                        A senha tem que conter no mínimo 6 caracteres e letras minusculas, maiusculas e números.
                    </Form.Text>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    required
                    title="Confirme a senha definida acima."
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                    type="password"
                    placeholder="Confirmar senha..."
                    defaultValue={password_confirmation}
                    onChange={e => setPassword_confirmation(e.target.value)}
                  />
                    <Form.Text className="text-muted">
                        Confirme a senha definida acima.
                    </Form.Text>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={e => {setShow(false); clearForm()}}>
                Cancelar
              </Button>
              <Button form = "user_form" variant="success" type="submit">
                Salvar
              </Button>
            </Modal.Footer>
          </Modal>
        </>
    );
}

export default CreateUsers;
