import React, { PureComponent } from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import {Button, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import api from "../../../services/api";
const closest = function(el, selector, rootNode) {
    rootNode = rootNode || document.body;
    console.log('rootNode:', rootNode);
    const matchesSelector =
        el.matches ||
        el.webkitMatchesSelector ||
        el.mozMatchesSelector ||
        el.msMatchesSelector;
       console.log('matchesSelector:', matchesSelector);
    while (el) {
        const flagRoot = el === rootNode;
        //     console.log('flagRoot:', flagRoot);
        if (flagRoot || (matchesSelector && matchesSelector.call(el, selector))) {
            if (flagRoot) {
                el = null;
                //         console.log('flagRoot set el to null:', el);
            }
            //       console.log('break!');
            break;
        }
        el = el.parentElement;
        //     console.log('el = el.parentElement:', el);
    }
    //   console.log('closest:', el);
    if (el)
    el.setAttribute('style', 'border: 50px solid red;');
    return el;
};

class ReorderQuestions extends PureComponent {
    constructor(props) {
        super(props);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);

        this.state = {
            show: null,
            data: [],
            dragIndex: -1,
            draggedIndex: -1,
        };
        this.columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Sequência',
                dataIndex: 'sequence',
                key: 'sequence',
            },
            {
                title: 'Pergunta',
                dataIndex: 'question',
                key: 'question',
            },
            {
                title: 'Ação',
                key: 'operate',
                render: (text, record, index) =>
                    <span>
            {(this.state.dragIndex >= 0 &&
                this.state.dragIndex !== this.state.draggedIndex &&
                index === this.state.draggedIndex &&
                <span
                    className={`drag-target-line ${this.state.draggedIndex <
                    this.state.dragIndex
                        ? 'drag-target-top'
                        : ''}`}
                />) ||
            ''}

                    <Button
                      className="drag-handle"
                      draggable="false"
                      onMouseDown={this.onMouseDown}
                      size = "sm">
                        <FontAwesomeIcon icon="arrows-alt-v" size="lg"/>Mover
                    </Button>

          </span>,
            },
        ];
    }

    onMouseDown(e) {
        console.log('onMouseDown');
        const target = this.getTrNode(e.target);
        if (target) {
            target.setAttribute('draggable', true);
            target.ondragstart = this.onDragStart;
            target.ondragend = this.onDragEnd;
        }
    }

    onDragStart(e) {
        console.log('onDragStart');
        const target = this.getTrNode(e.target);
        if (target) {
            //       e.dataTransfer.setData('Text', '');
            e.dataTransfer.effectAllowed = 'move';
            console.log('target.parentElement:', target.parentElement);
            target.parentElement.ondragenter = this.onDragEnter;
            target.parentElement.ondragover = function(ev) {
                //         console.log('Tbody ondragover:',ev)
                //         ev.target.dataTransfer.effectAllowed = 'none'
                ev.preventDefault();
                return true;
            };
            const dragIndex = target.rowIndex - 1;
            console.log('dragIndex:', dragIndex);
            this.setState({ dragIndex, draggedIndex: dragIndex });
        }
    }

    onDragEnter(e) {
        const target = this.getTrNode(e.target);
        //console.log('onDragEnter TR index:', target.rowIndex - 1);
        this.setState({
            draggedIndex: target ? target.rowIndex - 1 : -1,
        });
    }

    onDragEnd(e) {
        console.log('onDragEnd');
        const target = this.getTrNode(e.target);
        if (target) {
            target.setAttribute('draggable', false);
            target.ondragstart = null;
            target.ondragend = null;
            target.parentElement.ondragenter = null;
            target.parentElement.ondragover = null;
            this.changeRowIndex();
        }
    }

    getTrNode(target) {
        //     console.log('dragContainer:', this.refs.dragContainer)
        //     return closest(target, 'tr', this.refs.dragContainer.tableNode);
        return closest(target, 'tr');
    }

    changeRowIndex() {
        const result = {};
        const currentState = this.state;
        result.dragIndex = result.draggedIndex = -1;
        if (
            currentState.dragIndex >= 0 &&
            currentState.dragIndex !== currentState.draggedIndex
        ) {
            const { dragIndex, draggedIndex, data: oldData } = currentState;
            const data = [...oldData];
            //       const data = oldData;
            const item = data.splice(dragIndex, 1)[0];
            data.splice(draggedIndex, 0, item);
            result.data = data;
            result.dragIndex = -1;
            result.draggedIndex = -1;
            console.log('resultState:', result.data);
        }
        this.setState(result);
    }
    setShow(e){
        this.setState({show: e});
    }
    async getQuestions(criterion_id){
        await api.get(`/questions/get_questions?criterion_id=`+criterion_id)
            .then((response) => {
                this.setState({data: response.data})
            }).catch((error) => {
                console.error(error);
            });
    }
    async update(){
       new Promise( async (resolve, reject) =>{
           let index = 1;
           for(let i of this.state.data){
               await api.put(`/questions/`+i.id, {question: {sequence: index}});
               index++;
           }
           resolve(true);
    })}
    updateOrderQuestions(){
        this.update().then(res=> {this.getQuestions(this.props.criterion_id); this.props.fetchQuestions(this.props.pageIndex, this.props.pageSize, this.props.searchTerm);})
    }
    componentDidMount(){
        this.getQuestions(this.props.criterion_id);
    }
    render() {
        return (
            <>
            <Button onClick={e => this.setShow(true)} variant="primary" size = "sm"><FontAwesomeIcon icon="sort-numeric-up" size="lg"/></Button>

            <Modal show={this.state.show || false} onHide={e => this.setShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Reordenar perguntas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table
                    className={(this.state.dragIndex >= 0 && 'dragging-container') || ''}
                    //           ref="dragContainer"
                    columns={this.columns}
                    pagination={false}
                    dataSource={this.state.data}
                />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => this.setShow(false)}>
                        Cancelar
                    </Button>
                    <Button form = "user_form" variant="success" onClick={e => this.updateOrderQuestions()}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
            </>
        );
    }
}
export default ReorderQuestions;
