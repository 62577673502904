import React from "react";
import {Pagination} from "react-bootstrap";

function Paginator(props){

  const pageCount = props.pageCount;
  const pageIndex = props.pageIndex;
  const gotoPage = props.gotoPage;
  const previous = props.previous || (()=>{console.log("previous")})
  const next = props.next || (()=>{console.log("next")})
  const hasPrevious = props.hasPrevious
  const hasNext = props.hasNext
  const pages = []
  for (let i=0;i<pageCount;i++){
    pages.push(<Pagination.Item as = "button" key={i} active={i === pageIndex} onClick = {()=>(gotoPage(i))}>{i+1}</Pagination.Item>);
  }

  return (
    <>
      <Pagination size = "sm" className = "justify-content-center">
        <Pagination.First as = "button" disabled = { !hasPrevious } onClick = { ()=>(gotoPage(0)) }/>
        <Pagination.Prev as = "button" disabled = { !hasPrevious } onClick = { previous }/>
        {pages}
        <Pagination.Next as = "button" disabled = { !hasNext } onClick = { next }/>
        <Pagination.Last as = "button" disabled = { !hasNext } onClick = { ()=>(gotoPage(pageCount - 1)) }/>
      </Pagination>
    </>
  );
}

export default Paginator;
