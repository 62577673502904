
import React, { useState, useEffect } from "react";
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import api from "../../services/api";
import { useParams, useHistory } from "react-router-dom";
import  MyToast from "../../services/toast";
function EditTypeSectors(props) {
  const [isValidated, setIsValidated] = useState(false)
  const [typeSector, setTypeSector] = useState([]);
  const {id} = useParams();
  const history = useHistory();

  useEffect(() => {
    api.get(`/type_sectors/${id}`)
    .then((response) => {
      setTypeSector(response.data)
    }).catch((error) => {
      console.error(error);
    })
  },[id]);

  const handleSubmit = async event => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else {
      api.put(`type_sectors/${typeSector.id}`,{type_sector: typeSector})
      .then((response) => {
        MyToast.infoToast('Tipo de setor editado com sucesso!',5000, "top-center");
        back();
      }).catch((error) => {
        MyToast.errorToast('Erro ao editar tipo de setor: '+ error.message,5000, "top-center");
        console.error(error);
      })
    }
    setIsValidated(true);
  };

  function handleChange(e){
    e.preventDefault();
    const target = e.target;
    const value = target.value;
    setTypeSector({...typeSector, name: value});
  }

  function back(){
    history.push("/tiposDeSetor");
  }

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>Tipo de setor</Card.Title>
          <Form id = "form_type_sectors" noValidate validated= {isValidated} onSubmit = {handleSubmit}>
            <Form.Group>
              <Form.Control
                required
                type="text"
                placeholder="Nome do setor..."
                value={typeSector.name || ''}
                onChange={handleChange}
              />
            </Form.Group>
            <Button variant="secondary" onClick={back}>
              Cancelar
            </Button>
            <Button variant="success" type="submit">
              Salvar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}

export default EditTypeSectors;
