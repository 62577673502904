import React, {useState, useEffect, useCallback} from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Octicon, {Note} from '@githubprimer/octicons-react';
import api from "../../../services/api";
import {Table} from "react-bootstrap";

function Observations(props) {
    const [show, setShow] = useState('');
    const [observations, setObservations] = useState([]);

    const getObservations = useCallback(async () => {
        let obs = [];
        if (props.sector) {
            let response = await api.get(`/observations?sector=` + props.sector.id);
            obs = await response.data;
            setObservations(obs);
        }
    },[props.sector]);

    useEffect(() => {
      getObservations();
    }, [getObservations]);

    return (
      <>
        <Button variant="info" onClick={e => setShow(true)}><Octicon icon={Note}/> Observações</Button>

        <Modal show={show || false} onHide={e => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Nova observação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Observação</th>
                    </tr>
                  </thead>
                  <tbody>
                  {observations.length > 0 ? observations.map((obs, index) => {
                    return <tr key = {obs.id}>
                        <td>{obs.note}</td>
                    </tr>
                  }) : <tr></tr>}
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={e => setShow(false)}>
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    );
}

export default Observations;
