// TODO: Não permitir login de usuarios inativos
import React, { useState, useEffect } from "react";
import api from "../../services/api";
import "./Login.css";
import fms_logo from "./fms-logo.png";
// import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

// TODO: Deal with api's no response
// TODO: Exibit some feedback on failed logins

function Login (props){
  const context = useAuth();
  // const history = useHistory();
  const initialState = {
    email: '',
    password: '',
    error: ''
  }
  const [state, setState] = useState(initialState);

  useEffect(() => {
    document.body.style.background = "#007bff;";
    document.body.style.backgroundImage = "linear-gradient(to right, #0062E6, #33AEFF)";

    return function cleanup(){
      document.body.style.backgroundColor = "white";
      document.body.style.backgroundImage = null;
    }
  }, []);

  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setState({
      ...state,
      [name]: value
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const {email, password} = state;

    if (!email || !password) {
      setState({...state, error: "Preencha email e senha para continuar"});
    }else{
      try {
        const user = {
          user: {
            email: email,
            password: password
          }
        };
        const response = await api.post("/login", user, {
          headers: {
              'Content-Type': 'application/json',
          }}
        )
        if (response.data.id){
          context.login(response)
          // history.push("/");
        }else {
          throw new Error("LoginError");
        }
      } catch (e) {
        setState ({...state, error: "Houve um problema com o login, verifique suas credenciais."});
        console.error(e);
      }
    }
  };

  return (
    <div className = "container">
      <div className = "row min-vh-100 align-items-center">
        <div className = "col-sm-10 col-md-6 col-lg-4 mx-auto">
          <div className = "card card-signin">
            <div className="card-head text-center">
              <img
                src = {fms_logo}
                height = "100%"
                alt = "FMS"
              />
            </div>
            <div className = "card-body card-signin">
              <h5 className="card-title text-center">
                SAAB
              </h5>
              <form
                className="form-signin"
                onSubmit = {handleSubmit}
              >
              {state.error && <p className = "text-danger text-justify">{state.error}</p>}
                <div className = "form-group form-label-group">
                  <input
                    id = "email"
                    className = "form-control"
                    name = "email"
                    type = "email"
                    value = {state.email}
                    onChange = {handleInputChange}
                    placeholder = ""
                  />
                  <label htmlFor = "email">Email</label>
                </div>
                <div className = "form-group form-label-group">
                  <input
                    id = "password"
                    className = "form-control"
                    name = "password"
                    type = "password"
                    value = {state.password}
                    onChange = {handleInputChange}
                    placeholder = ""
                  />
                  <label
                    htmlFor = "password">
                      Senha
                  </label>
                </div>
                <div className = "form-group">
                  <button
                    className = "btn btn-lg btn-primary btn-block text-uppercase"
                  >
                    Entrar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;
