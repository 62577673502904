// TODO: Display success and error messages
import React, {useState} from "react";
import {Button, Modal, Card, Row, Col} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import api from "../../services/api";
import  MyToast from "../../services/toast";
function AddSectors(props){
  const [show, setShow] = useState(false);
  const [selected_switches, setSelectedSwitches] = useState([]);

  function handleAddSector(){
    setShow(true);
  }

  function handleChange(e){
    let target = e.target;
    let checked = target.checked;
    let value = target.value;
    let checked_sectors = selected_switches;
    if (checked_sectors.includes(value)) {
      if (!checked) {
        checked_sectors.splice(checked_sectors.indexOf(value),1);
      }
    }else{
      if (checked) {
        checked_sectors.push(value)
      }
    }
    setSelectedSwitches(checked_sectors);
  };

  async function handleSubmit(e){
    e.preventDefault();
    let new_sectors = [];
    for (let i = 0;i<selected_switches.length; i++){
      new_sectors.push({active: true, ub_id: props.ubs.id, type_sector_id: selected_switches[i]})
    }
    await api.post("/sectors",{sectors: {sectors: new_sectors}})
    .then(function(response){
      MyToast.successToast('Setores cadastrados com sucesso!',5000, "top-center");
      props.fetchSectors();
    }).catch(function(error){
      MyToast.errorToast('Erro ao cadastrar a setores: '+ error.message,5000, "top-center");
      console.error(error);
    }).finally(function(){
      setSelectedSwitches([]);
      setShow(false);
    })
  };

  function closeModal(e){
    setSelectedSwitches([]);
    setShow(false);
  }

  let sector_list = props.type_sectors.map(type_sector=>
    <Form.Check
      key = {type_sector.id}
      type="switch"
      defaultChecked = {props.sectors.find((sector)=> sector.type_sector_id === type_sector.id)}
      disabled = {props.sectors.find((sector)=> sector.type_sector_id === type_sector.id)}
      id= {`sectors_switch_${type_sector.id}`}
      name= {`sectors_switch_${type_sector.id}`}
      value = {type_sector.id}
      label={type_sector.name}
      custom = {true}
      onChange = {handleChange}
    />
  );
    return (
      <>
        <Button variant = "primary" onClick={handleAddSector} size = "sm">
          <FontAwesomeIcon icon = {faPlus} size = "lg" />
        </Button>
        <Modal show={show || false} onHide={closeModal} >
          <Modal.Header closeButton>
            <Modal.Title>Adicionar setores à UBS</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              <Card.Header>
              </Card.Header>
              <Card.Body>
                <Card.Title>{props.ubs.name}</Card.Title>
                <Card.Text>
                  Marque os setores que estão presentes nesta UBS
                </Card.Text>
                <Row>
                  <Col >
                    <Form name = "form" onSubmit={handleSubmit}>
                      {sector_list}
                      <Button variant="primary" type="submit" >Confirmar</Button>
                    </Form>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </>
  )
}

export default AddSectors;
