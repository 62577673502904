import React from "react";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import ListUBS from './ListUBS';
import api from '../../services/api';
import { UserContext} from "../../context/UserContext";
import { Link } from "react-router-dom";

class SelectUbs extends React.Component{
  static contextType = UserContext;
    constructor(props){
        super(props);
        this.state={
            ubs_list: [],
            selected_ubs: null
        };
        this.loadUbsList = this.loadUbsList.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    // TODO: insert api call here
    async loadUbsList(){
        let ubs_list;
        // Make a request for a user with a given ID
        await api.get('ubs?regional='+this.context.regional_id)
            .then(function (response) {
                ubs_list = response.data;
            })
            .catch(function (error) {
                // handle error
                ubs_list = [];
            })
            .finally(function () {
                // always executed
            });
        this.setState({ubs_list: ubs_list});
    };
    handleSubmit(event){
        event.preventDefault();
    };
    handleChange(event){
        this.setState({selected_ubs: event.value});
    }
    componentDidMount(){
        this.loadUbsList();
    }
    render(){
        return (
            <div className = "col-sm-10 col-md-7 col-lg-5 mx-auto ">
                <Card>
                    <Card.Header  className = "text-center">
                        Nova Avaliação
                    </Card.Header>
                    <Card.Body>
                        <Card.Title  className = "text-center">
                            Escolha de UBS
                        </Card.Title>
                        <Card.Subtitle className = "text-justify p-1">
                            {this.props.subtitulo || "Escolha uma UBS entre as opções abaixo." }
                        </Card.Subtitle>
                        <Form onSubmit = {this.handleSubmit}>
                            <ListUBS ubs_list = {this.state.ubs_list} handleChange = {this.handleChange} />
                            {this.state.selected_ubs >0?
                                <Link className="float-right btn btn-primary"
                                   to={"/avaliacoes/" + this.state.selected_ubs}>
                                    Selecionar
                                </Link>
                                : <Link className="float-right btn btn-primary disabled"
                                     to={"/avaliacoes/" + this.state.selected_ubs}>
                                    Selecionar
                                </Link>
                            }
                            <Link className="btn btn-outline-primary" to={"/"}>Voltar</Link>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default SelectUbs;
