import React from "react";
import ReactLoading from "react-loading";
import "../../App.css";

function Loading(props){

  return(
    <div className="App">
      <header className="App-header">
        <ReactLoading type = {"bars"} color={"white"}/>
      </header>
    </div>
  )
}

export default Loading;
