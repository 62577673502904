
import React, { useState, useEffect, useCallback } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import api from "../../../services/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import  MyToast from "../../../services/toast";

function EditCriterions(props) {
  const [isValidated, setIsValidated] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type_sectors, setTypeSectors] = useState([]);
  const [active, setActive] = useState(false);
  const [type_sector_id, setTypeSectorId] = useState(null);
  const [show, setShow] = useState(false);

  const resetForm = useCallback(() => {
    setIsValidated(false);
    setName(props.criterion.name);
    setDescription(props.criterion.description);
    setActive(props.criterion.active);
    setTypeSectorId(props.criterion.type_sector_id);
    setShow(false);
  }, [props.criterion]);

  const handleSubmit = (async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }else {
      var criterion = {
        criterion: {
          name: name || props.criterion.name,
          description: description,
          active: active,
          type_sector_id: type_sector_id
        }
      };
      await api.put(`/criterions/${props.criterion.id}`, criterion)
      .then((response) => {
        setShow(false);
          MyToast.infoToast('Critério '+ criterion.criterion.name +' alterado com sucesso!',5000, "top-center");
        setName('');
        props.fetchCriterion(props.pageIndex, props.pageSize, props.searchTerm);
      }).catch((error) => {
          MyToast.errorToast('Erro ao alterar o critério: '+ error.message,5000, "top-center");
      })
    }
    setIsValidated(true);
  });

  const getTypeSectors = useCallback(async ()=>{
      let response = await api.get(`/type_sectors`);
      setTypeSectors(response.data);
  }, []);

  useEffect(() => {
      getTypeSectors();
      resetForm();
  },[getTypeSectors, resetForm]);

  const handleChange = () => {
      setActive(!active)
  }

  const options = type_sectors.map((typesec, index) => {
      return { key: typesec.id, value: typesec.id, label: typesec.name}
  })
  const selected_options = options.find(elm => { return elm.value === props.criterion.type_sector_id })

  return (
      <>
          <Button variant = "warning" size = "sm">
              <FontAwesomeIcon icon="pencil-alt" onClick={e => setShow(true)} size="lg"/>
          </Button>
          <Modal show={show || false} onHide={e => setShow(false)}>
              <Modal.Header closeButton>
                  <Modal.Title>Editar Critério</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <Form id = "form_criterions_edit" noValidate validated = {isValidated} onSubmit = {handleSubmit}>
                  <Form.Group>
                      <Form.Control
                          required
                          type="text"
                          placeholder="Critério..."
                          defaultValue={name || ''}
                          onChange={e => setName(e.target.value)}
                          maxLength="50"
                          minLength="3"
                      />
                      <Form.Text className="text-muted">
                          Escreva o nome do critério de no mínimo 3 e máximo 50 caracteres.
                      </Form.Text>
                  </Form.Group>
                  <Form.Group>
                      <Form.Control
                          required
                          type="text"
                          placeholder="Descrição do criterio..."
                          defaultValue={description || ''}
                          onChange={e => setDescription(e.target.value)}
                          maxLength="200"
                          minLength="10"
                      />
                      <Form.Text className="text-muted">
                          Descreva o que o critério aborda, mínimo 10 e máximo 200 caracteres.
                      </Form.Text>
                  </Form.Group>
                <Form.Group controlId="formSelectTypeSector">
                  <Form.Label>Tipo de Setor</Form.Label>
                  <Select
                    placeholder = "Selecione um tipo de setor"
                    onChange={e => setTypeSectorId(e.value)}
                    defaultValue = {selected_options}
                    options = {options}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Check label="Ativo?" value={active}
                  checked={active}
                  onChange={handleChange}
                  />
                </Form.Group>
                <Button variant="secondary" onClick={resetForm}>
                  Cancelar
                </Button>
                <Button variant="success" type="submit">
                  Salvar
                </Button>

              </Form>

              </Modal.Body>
              <Modal.Footer>
              </Modal.Footer>
          </Modal>
      </>
  );
}

export default EditCriterions;
