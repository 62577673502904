import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import api from "../../../services/api";
import  MyToast from "../../../services/toast";

function CreateQuestions(props) {
  const [question, setQuestion] = useState('');
  const [typequestion, setTypeQuestion] = useState(null);
  const [criterions, setCriterions] = useState([]);
  const [type_sectors, setTypeSectors] = useState([]);
  const [active, setActive] = useState(true);
  const [criterion_id, setCriterionId] = useState(null);
  const [show, setShow] = useState('');
  const [isValidated, setIsValidated] = useState(false);

  const handleSubmit = (async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setIsValidated(true);
    }else { 
      var new_question = {
          question: {
              question: question,
              active: active,
              type_question: typequestion,
              criterion_id: criterion_id
          }
      };
      await api.post(`/questions`, new_question)
      .then(()=>{
        MyToast.successToast('Pergunta cadastrada com sucesso!',5000, "top-center");
        setShow(false);
        clearForm();
        props.fetchQuestions(props.pageIndex, props.pageSize, props.searchTerm);

      })
      .catch((error)=>{
        MyToast.errorToast('Erro ao cadastrar a pergunta: '+ error.message,5000, "top-center");
        setIsValidated(true)
      });
    }
  });

  const getCriterionsFilter = (async (type_sector)=>{
      let response = await api.get(`/criterions?type_sector=`+type_sector);
      setCriterions(response.data);
  });

  const getTypeSectors = (async ()=>{
      let response = await api.get(`/type_sectors/with_criterions`);
      setTypeSectors(response.data);
  });

  useEffect(() => {
     getTypeSectors();
  },[]);

  const handleChange = () => {
      setActive(!active)
  }

  const criterions_options = criterions.map((criterion, index) => {
    return {key: criterion.id, value: criterion.id, label: criterion.name}
  })

  const type_sectors_options = type_sectors.map((type_sector, index) => {
    return {key: type_sector.id, value: type_sector.id, label: type_sector.name}
  })

  function clearForm(){
    setQuestion('');
    setTypeQuestion(null);
    setActive(true);
    setCriterionId(null);
    setIsValidated(false);
  }

  function handleSelectCriterion(option, {action}){
    switch (action){
      case 'select-option':
        setCriterionId(option.value);
        break;
      default:
        setCriterionId(null);
    }
  }

  return (
    <>
      <Button onClick={e => setShow(true)} variant="primary" className="float-right create_task_btn">Cadastrar nova pergunta</Button>

      <Modal show={show || false} onHide={e => {setShow(false);clearForm();}}>
        <Modal.Header closeButton>
          <Modal.Title>Nova Pegunta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id = "QuestionForm" noValidate validated = {isValidated} onSubmit={handleSubmit}>
            <Form.Group controlId="formSelectTypeSector">
              <Form.Label>Tipo de Setor</Form.Label>
              <Select
                placeholder = "Selecione um tipo de setor"
                onChange={e => {clearForm(); getCriterionsFilter(e.value)}}
                options = {type_sectors_options}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control 
                required
                as="textarea"
                type="text"
                placeholder="Pergunta..."
                value={question || ''}
                onChange={e => setQuestion(e.target.value)}
                maxLength="300"
                minLength="5"
                form = "QuestionForm"
              />
              <Form.Control.Feedback>certo!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Por favor, preencha o texto da pergunta.
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                  Escreva a pergunta, mínimo 5 e máximo 300 caracteres.
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formSelectCriterion">
              <Form.Label>Critério</Form.Label>
              <Select
                required
                placeholder = "Selecione um critério"
                onChange={(option, {action}) => handleSelectCriterion(option, {action})}
                options = {criterions_options}
                form = "QuestionForm"
                isClearable = {true}
              />
            </Form.Group>
            <div className = "form-group form-label-group">
              <select className="custom-select"
                      onChange={e => setTypeQuestion(e.target.value)}
                      value = {typequestion || ''}
                      required
              >
                <option value = "">Selecione o tipo de pergunta</option>
                <option value="1">Pontuação 1 a 5</option>
                <option value="2">Opções de escolha (não, talvez, sim)</option>
                <option value="3">Sim ou não</option>
              </select>
            </div>
            <Form.Check label="Ativo?"
              value={active}
              checked={active}
              onChange={handleChange}
              form = "QuestionForm"/>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={e => {setShow(false); clearForm();}} form = "QuestionForm">
            Cancelar
          </Button>
          <Button variant="success" type="submit" form = "QuestionForm">
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateQuestions;
