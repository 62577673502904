// TODO: Display success and errors messages
import React, { useState} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import api from "../../services/api";
import  MyToast from "../../services/toast";

function EditSectors(props){
  const [show, setShow] = useState(false);

  function handleEditSector(){
    setShow(true);
  }

  async function handleChange(e){
    let target = e.target;
    let checked = target.checked;
    let value = target.value;
    const selected_sector = props.sectors.find(sector => sector.id === Number(value));
    selected_sector.active = checked;

    await api.patch(`/sectors/${selected_sector.id}`,{sector: selected_sector})
    .then(function(response){
      MyToast.infoToast('Setor editado com sucesso!',5000, "top-center");
    }).catch(function(error){
      MyToast.errorToast('Erro ao editar a setor: '+ error.message,5000, "top-center");
      console.error(error);
    }).finally(function(){

    })
  };

  async function handleSubmit(e){
    e.preventDefault();
    setShow(false);
  };

  function closeModal(e){
    setShow(false);
  }

  let sector_list = props.sectors.map(sector=>
    <Form.Check
      key = {sector.id}
      id = {sector.id}
      value = {sector.id}
      type="switch"
      defaultChecked = {sector.active}
      label={sector.type_sector.name}
      custom = {true}
      onChange = {handleChange}
    />
  );
    return (
      <>
        <Button variant = "warning" onClick={handleEditSector} size = "sm">
          <FontAwesomeIcon icon = {faEdit} size = "lg" />
          </Button>
        <Modal show={show || false} onHide={closeModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Editar setores presentes na UBS</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              <Card.Header>

              </Card.Header>
              <Card.Body>
                <Card.Title>{props.ubs.name}</Card.Title>
                <Card.Text>
                  Marque os setores que estão ativos nesta UBS
                </Card.Text>
                <Row>
                  <Col >
                    <Form name = "form" onSubmit={handleSubmit}>
                      {sector_list}
                      <Button variant="primary" type="submit" >Fechar</Button>
                    </Form>
                  </Col>

                </Row>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </>
  )
}

export default EditSectors;
