// TODO: Display success and errors messages
import React, { useMemo, useCallback } from 'react';
import { Link } from "react-router-dom";
import api from "../../services/api";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MyTable from "../table/Table";

function List(props){
  //chama endpoint typeSector do rails : DELETE


  const columns = useMemo(
    ()=>[{
      Header: 'Nome',
      accessor: 'name'
    },
    {
      Header: "Ações",
      Cell: ({row})=>(
        <ButtonGroup className="mr-2">

          <Link to = {`/tiposDeSetor/${row.original.id}` } className = "btn btn-sm btn-warning">
            <FontAwesomeIcon icon="pencil-alt" size = "lg"/>
          </Link>

          <DeleteButton
          typeSector = { row.original }
          pageIndex = { props.pageIndex }
          pageSize = { props.pageSize }
          searchTerm = { props.searchTerm }
          fetchTypeSectors = { props.fetchTypeSectors }
          />
        </ButtonGroup>
      )
    }],[props.fetchTypeSectors, props.searchTerm, props.pageSize, props.pageIndex]
  );

  return (
    <>
      <MyTable
        columns={columns}
        data = {props.typeSectors}
        fetchData = {props.fetchTypeSectors}
        loading = {props.loading}
        pageCount = {props.pageCount}
        search = {{ placeholder: "Nome",
          handleChange: props.handleChange,
          handleClick: props.handleSearch
        }}
        searchTerm = { props.searchTerm }
        pageIndex = {props.pageIndex}
        pageSize = {props.pageSize}
        setPageIndex = {props.setPageIndex}
        setPageSize = {props.setPageSize}
        total = {props.total}
        total_filtered = {props.total_filtered}
      />
    </>
  )
}

function DeleteButton({typeSector, fetchTypeSectors, pageIndex, pageSize, searchTerm }){

  const deleteTypeSector = useCallback(() => {
    console.log(fetchTypeSectors, pageIndex, pageSize, searchTerm);
      if (window.confirm(`Are you sure you want to delete: "${typeSector.name}"`)) {
        api.delete(`/type_sectors/${typeSector.id}`)
        .then((response) => {
          fetchTypeSectors({pageIndex, pageSize, searchTerm});
        }).catch((error) => {
          console.error(error);
          alert(`Não foi possivel remover o registro. ${error.response.data}`)

        })
      }
  },[fetchTypeSectors, pageIndex, pageSize, searchTerm, typeSector])

  return(
    <Button variant = "danger" onClick={deleteTypeSector} size = "sm">
      <FontAwesomeIcon icon="trash-alt" size="lg"/>
    </Button>
  );
}

export default List;
