import React from "react";
import {Button} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom"
import Octicon, {Reply, X, Verified} from '@githubprimer/octicons-react';
import api from "../../../services/api";
import { useUser } from "../../../context/UserContext";

function ButtonsDashBoardEvaluation(props) {
  const user = useUser();
  let history = useHistory();

  const handleCancel = async () => {
    if (window.confirm(`Você tem certeza que deseja CANCELAR esta avaliação?`)) {
      props.evaluation.status = "Cancelado";
      await api.put(`/evaluations/${props.evaluation.id}`, {evaluation: props.evaluation})
      .then(()=>{
        history.push("/")
      }).catch((error) => {
        console.error(error);
      });
    }
  };

  const handleFinish = async () => {
    if (window.confirm(`Você tem certeza que deseja CONCLUIR esta avaliação?`)) {
      props.evaluation.status = "Concluído";
      await api.put(`/evaluations/${props.evaluation.id}`, {evaluation: props.evaluation})
      .then(()=>{
        history.push("/")
      }).catch((error) => {
        console.error(error);
      });
    }
  };

  function canCancel(){
    return user.role ===1 && props.evaluation && props.evaluation.id
  }

  function canFinish(){
    return props.evaluation
    && props.evaluation.status !== 'Cancelado'
    && props.evaluation.content
    && props.evaluation.total_criterions_answered === props.evaluation.total_criterions
    && props.evaluation.total_criterions > 0
    && user.role ===1
  }

  return (
    <div className="col-md-12">
      <div className="float-left">
        <Link className="btn btn-outline-primary" to ={"/nova_avaliacao"}><Octicon icon={Reply}/> Voltar</Link>
      </div>
      <div className="float-right">
          {canCancel()
              ?<Button variant="danger" onClick={e => handleCancel()} disabled = {!(props.evaluation.id > 0)}><Octicon icon={X}/> Cancelar</Button>
              :<></>
          }
          { canFinish()
              ? <Button variant="primary" onClick={e => handleFinish()} disabled = {props.evaluation.total_criterions > 0&&(props.evaluation.total_criterions !== props.evaluation.total_criterions_answered)}><Octicon icon={Verified}/>
                  Finalizar</Button>
              :<></>
          }
      </div>
    </div>
  )
}

export default ButtonsDashBoardEvaluation;
