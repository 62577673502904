import React, { useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import api from "../../services/api";
import PageHeader from "../page_header/PageHeader";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import CreateCriterions from './create_criterions/CreateCriterions';
import EditCriterions from "./edit_criterions/EditCriterions";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Card from 'react-bootstrap/Card';
import MyTable from '../table/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPencilAlt, faTrashAlt, faCheckCircle, faBan } from '@fortawesome/free-solid-svg-icons'

library.add(faPencilAlt, faTrashAlt, faCheckCircle, faBan)

function Criterions (props) {
  const [criterions, setCriterions] = useState([]);
  const [total, setTotal] = useState(0);
  const [total_filtered, setTotalFiltered] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchCriterions = useCallback(({pageIndex, pageSize, searchTerm}) => {
    setLoading(true);
    let expression = `/criterions?page=${pageIndex+1}&page_size=${pageSize}`;
    if (typeof(searchTerm) !== 'undefined') {
      expression = expression.concat(`&search=${searchTerm}`)
    }
    api.get(expression)
    .then((response) => {
      setCriterions(response.data)
      setTotal(response.headers['x-total-registries']);
      setTotalFiltered(response.headers['x-total-filtered']);
      setPageCount(Math.ceil(response.headers['x-total-filtered']/pageSize));
      setLoading(false);
    }).catch((error) => {
        console.error(error);
    })
  },[]);

  const columns = useMemo(() => [
    {
      Header: "Nome",
      accessor: "name"
    },
    {
      Header: "Decrição",
      accessor: "description"
    },{
      Header: "Ativo?",
      accessor: "active",
      Cell: ({row}) => {

        return row.original.active? "Sim":"Não"
      }
    },{
      Header: "Tipo de Setor",
      accessor: "type_sector.name"
    },{
      Header: "Ações",
      Cell: ({row}) => (
        <CriterionActions
          criterion = { row.original }
          fetchCriterions = { fetchCriterions }
          pageIndex = { pageIndex }
          pageSize = { pageSize }
        />
      )
    }
  ],[fetchCriterions, pageSize, pageIndex]);

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    fetchCriterions({pageIndex, pageSize, searchTerm})
  },[fetchCriterions, pageIndex, pageSize, searchTerm] )

  function handleChange(e){
    e.preventDefault();
    let target = e.target;
    setSearchTerm(target.value)
  }

  return (
    <>
      <PageHeader>
        <Link to= "/" className = "breadcrumb-item"> Home </Link>
        <Breadcrumb.Item active>Critérios</Breadcrumb.Item>
      </PageHeader>
      <Card>
        <Card.Body>
          <Card.Title>Critérios</Card.Title>
          <MyTable
            columns = {columns}
            data = {criterions}
            fetchData = {fetchCriterions}
            pageCount = {pageCount}
            total = {total}
            total_filtered = { total_filtered }
            loading = {loading}
            pageIndex = {pageIndex}
            pageSize = {pageSize}
            setPageIndex = {setPageIndex}
            setPageSize = {setPageSize}
            search = {{ placeholder: "Nome",
                        handleChange: handleChange,
                        handleClick: handleSearch
                      }}
            searchTerm = { searchTerm }
          />
          <CreateCriterions
            criterions = { criterions }
            setCriterions = { setCriterions }
            fetchCriterions={fetchCriterions}
            pageIndex = { pageIndex }
            pageSize = { pageSize }
            searchTerm = { searchTerm }
          />
        </Card.Body>
      </Card>
    </>
  );
}

function CriterionActions(props){
  const pageIndex = props.pageIndex;
  const pageSize = props.pageSize;
  const searchTerm = props.searchTerm;
  const fetchCriterions = props.fetchCriterions;
  const criterion = props.criterion

  const deleteCriterion = useCallback(() => {
    api.delete(`/criterions/${criterion.id}`)
    .then((response) => {
      fetchCriterions({pageIndex, pageSize, searchTerm})
    }).catch((error) => {
      console.error(error);
    })
  },[fetchCriterions, pageIndex, pageSize, searchTerm, criterion])

  return(
    <ButtonGroup className="mr-2">
      <EditCriterions
        criterion={props.criterion}
        fetchCriterion={fetchCriterions}
        pageIndex = { pageIndex }
        pageSize = { pageSize }
      />
      <Button variant = "danger" onClick={deleteCriterion} size = "sm">
        <FontAwesomeIcon icon="trash-alt" size="lg"/>
      </Button>
    </ButtonGroup>
  )
}

export default Criterions;
