// TODO: Handle logged user
// TODO: handle exclusion of logged user
// TODO: enforce unic registration
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PageHeader from "../page_header/PageHeader";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';
import MyTable from '../table/Table';
import api from "../../services/api";
import CreateUsers from './create_users/CreateUsers';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPencilAlt, faTrashAlt, faCheckCircle, faBan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditUsers from "./edit_users/EditUsers";

library.add(faPencilAlt, faTrashAlt, faCheckCircle, faBan);

function Users (props){

  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [total_filtered, setTotalFiltered] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [establishments, setEstablishments] = useState([]);
  const [regionals, setRegionals] = useState([]);

  async function fetchEstablishments(){
    await api.get(`/ubs`)
    .then((response) => {
      setEstablishments(response.data)
    }).catch((error) => {
      console.error(error);
    })
  }

  async function fetchRegionals(){
    await api.get(`/regionals`)
    .then((response) => {
      setRegionals(response.data)
    }).catch((error) => {
      console.error(error);
    });
  }

  useEffect(() => {
    fetchEstablishments();
    fetchRegionals();
  },[])

  const fetchUsers = useCallback(({pageIndex, pageSize, searchTerm}) => {
    setLoading(true);
    let expression = `/users?page=${pageIndex+1}&page_size=${pageSize}`;
    if (typeof(searchTerm) !== 'undefined') {
      expression = expression.concat(`&search=${searchTerm}`)
    }
    api.get(expression)
    .then((response) => {
      setUsers(response.data)
      setTotal(response.headers['x-total-registries']);
      setTotalFiltered(response.headers['x-total-filtered']);
      setPageCount(Math.ceil(response.headers['x-total-filtered']/pageSize));
      setLoading(false);
    }).catch((error) => {
        console.error(error);
    })
  },[]);

  const columns = useMemo(() => [
    {
      Header: "Nome",
      accessor: "name"
    },{
      Header: "email",
      accessor: "email"
    },{
      Header: "Ativo?",
      accessor: "active",
      Cell: ({row}) => {
        return row.original.active? "Sim":"Não"
      }
    },{
      Header: "Papel",
      accessor: "role",
      Cell: ({row}) => {
        switch (row.original.role){
          case 0:
            return 'Administrador'
          case 1:
            return 'Avaliador'
          case 2:
            return 'Coordenador'
          case 3:
            return 'Visualizador'
          default:
            return ''
        }
      }
    },{
      Header: "Matrícula",
      accessor: "registration"
    },{
      Header: "Regional",
      accessor: "regional.name"
    },{
      Header: "Unidade",
      accessor: "ub.name"
    },{
      Header: "Ações",
      Cell: ({row}) => (
        <UserActions
          user = {row.original}
          setUsers = {setUsers}
          establishments = {establishments}
          regionals = {regionals}
          fetchUsers = { fetchUsers }
          pageIndex = { pageIndex }
          pageSize = { pageSize }
        />
      )
    }
  ],[establishments, regionals, fetchUsers, pageSize, pageIndex ]);

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    fetchUsers({pageIndex, pageSize, searchTerm})
  },[fetchUsers, pageIndex, pageSize, searchTerm] )

  function handleChange(e){
    e.preventDefault();
    let target = e.target;
    setSearchTerm(target.value)
  }

  return (
    <>
      <PageHeader>
        <Link to= "/" className = "breadcrumb-item"> Home </Link>
        <Breadcrumb.Item active>Usuários</Breadcrumb.Item>
      </PageHeader>
      <Card>
        <Card.Body>
          <Card.Title>Usuários</Card.Title>
          <MyTable
            columns = {columns}
            data = {users}
            fetchData = {fetchUsers}
            pageCount = {pageCount}
            total = {total}
            total_filtered = {total_filtered}
            search = {{ placeholder: "Nome ou email",
                        handleChange: handleChange,
                        handleClick: handleSearch
                      }}
            searchTerm = { searchTerm }
            loading = {loading}
            pageIndex = {pageIndex}
            pageSize = {pageSize}
            setPageIndex = {setPageIndex}
            setPageSize = {setPageSize}
          />
          <CreateUsers
            user = {{
                name: "",
                active: true,
                registration: '',
                role: null,
                email: '',
                regional_id: null,
                ub_id: null,
                password_confirmation: null,
                password: null}
            }
            setUsers={setUsers}
            establishments = {establishments}
            regionals = {regionals}
            fetchUsers = { fetchUsers }
            pageIndex = { pageIndex }
            pageSize = { pageSize }
          />
        </Card.Body>

      </Card>
    </>
  );
}


function UserActions(props){
  const pageIndex = props.pageIndex;
  const pageSize = props.pageSize;
  const searchTerm = props.searchTerm;
  const fetchUsers = props.fetchUsers;
  const user = props.user

  const deleteUser = useCallback(() => {
    api.delete(`/users/${user.id}`)
    .then((response) => {
      fetchUsers({pageIndex, pageSize, searchTerm})
    }).catch((error) => {
      console.error(error);
    })
  },[fetchUsers, pageIndex, pageSize, searchTerm, user])

  return(
    <ButtonGroup className="mr-2">
      <EditUsers
        user={props.user}
        setUsers = {props.setUsers}
        establishments = {props.establishments}
        regionals = {props.regionals}
        fetchUsers = { fetchUsers }
        pageIndex = { pageIndex }
        pageSize = { pageSize }
      />
      <Button variant = "danger" onClick={deleteUser} size = "sm">
        <FontAwesomeIcon icon="trash-alt" size="lg"/>
      </Button>
    </ButtonGroup>
  )
}
export default Users;
