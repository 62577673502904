import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from 'react-router-dom';
import PageHeader from "../../page_header/PageHeader";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert'
import api from "../../../services/api";
import Skeleton from 'react-loading-skeleton';
import StatusEvaluation from "./statusEvaluation";
import ButtonsDashBoardEvaluation from "./buttons";
import TableDashBoardEvaluation from "./table";
import { useUser } from "../../../context/UserContext";

function DashBoardEvaluation(props){
  const [ubs, setUbs] = useState({});
  const [evaluation, setEvaluation] = useState({});
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const user = useUser();

  const getUbs = useCallback(async (id) => {
    await api.get(`/ubs/${id}`)
    .then((response) => {
      setUbs(response.data);
    }).catch((error) => {
      console.error(error);
    })
  },[]);

  const createNewEvaluation = useCallback( async ()=>{
    await api.get(`/get_ubs_data/${params.ubs}`)
    .then((response) => {
      for(let sector of response.data.data){
        sector.total_rate = 0;
        sector.total_criterions = sector.type_sector.criterions.length;
        sector.total_criterions_answered = 0;
        for(let criterion of sector.type_sector.criterions){
          criterion.questions_answered = 0;
          criterion.rate = 0;
          for(let question of criterion.questions){
            question.rate = null
          }
        }
      }
      const evaluation = {
        ub_id: Number(params.ubs),
        user_id: user.id,
        status: "Em andamento",
        total_rate: 0,
        total_criterions: response.data.num_crit,
        total_criterions_answered: 0,
        content: response.data.data
      }
      setEvaluation(evaluation)
      localStorage.setItem('evaluation', JSON.stringify(evaluation))
      setLoading(false)
    }).catch((error) => {
      console.error(error);
    })
  },[params.ubs, user.id])

  const getEvaluation = useCallback((id) => {
    setLoading(true);
    api.get(`/evaluations/get_last?ub_id=`+id)
    .then((response) => {
      if (response.data) {
        setEvaluation(response.data);
        localStorage.removeItem('evaluation');
        localStorage.setItem('evaluation', JSON.stringify(response.data));
        setLoading(false);
      }else{
        if (localStorage.getItem('evaluation')) {
          let evaluation = JSON.parse(localStorage.getItem('evaluation'));
          if (evaluation.ub_id === id) {
            setEvaluation(evaluation);
            setLoading(false);
          }else {
            localStorage.removeItem('evaluation');
            createNewEvaluation();
          }
        }else {
          createNewEvaluation();
        }
      }
    }).catch((error) => {
      console.error(error);
      if (localStorage.getItem('evaluation')) {
        let evaluation = JSON.parse(localStorage.getItem('evaluation'));
        if (evaluation.ub_id === id) {
          setEvaluation(evaluation);
          setLoading(false);
        }else {
          localStorage.removeItem('evaluation');
          createNewEvaluation();
        }
      }else {
        createNewEvaluation();
      }
    })
  },[createNewEvaluation]);

  useEffect(() => {
    getUbs(params.ubs);
    getEvaluation(params.ubs);
  },[getEvaluation, getUbs, params.ubs])

  return (
    loading?<Skeleton />:
    <>
      <PageHeader>
        <Link to= "/" className = "breadcrumb-item"> Home </Link>
        <Link to= {"/nova_avaliacao"} className = "breadcrumb-item"> {ubs.name || <Skeleton />} </Link>
        <Breadcrumb.Item active>Resumo da avaliação</Breadcrumb.Item>
      </PageHeader>
      <Card>
        <Card.Body>
          <Card.Title>Avaliação - {ubs.name || <Skeleton />}</Card.Title>
          <Alert show = {evaluation.user_id !== user.id} variant = "warning">
          <Alert.Heading>Avaliação Iniciada por outro avaliador</Alert.Heading>
          </Alert>
          <Row>
            <StatusEvaluation evaluation={evaluation}/>
          </Row>
          <Row className = "mt-3">
            <Col lg={12}>
              <TableDashBoardEvaluation evaluation={evaluation}/>
            </Col>
          </Row>
          <Row>
            <ButtonsDashBoardEvaluation ubs={ubs} evaluation={evaluation}/>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default DashBoardEvaluation;
