import React from "react";
import {Breadcrumb} from "react-bootstrap";
import "./index.scss";

function PageHeader(props){  return (
  <Breadcrumb>
    {React.Children.map(props.children, (child)=>(child))}
  </Breadcrumb>
)};
export default PageHeader;
