import React from "react";
import Rate from 'rc-rate';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Badge from 'react-bootstrap/Badge'
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

function CriterionsNewEvaluation({evaluation, setEvaluation}) {
  const [e, setE] = React.useState(0);
  let updated_evaluation = JSON.parse(JSON.stringify(evaluation));

  const params = useParams();

  function handleChange(rate, question){
    switch (question.type_question) {
      case 1:
        question.rate = rate;
        break;
      case 2:
        question.rate = Number(rate.target.value);
        break;
      case 3:
        question.rate = rate.target.checked? 5: 0;
        break;
      default:
        break;
    }
    updateRate()
  }

  function updateRate(){
    let evaluation_rate = 0;
    let evaluation_criterions_answered = 0;
    for (let sector of updated_evaluation.content) {
      let criterions_answered = 0;
      let sector_rate = 0;
      for (let criterion of sector.type_sector.criterions) {
        let questions_answered = 0;
        let criterion_rate = 0;
        for (let question of criterion.questions) {
          if (question.rate!= null) {
            criterion_rate += question.rate;
            questions_answered += 1;
          }
        }
        criterion.questions_answered = questions_answered;
        criterion.rate = criterion_rate/criterion.questions.length;
        if (criterion.questions_answered === criterion.questions.length) {
          criterions_answered += 1;
        }
        sector_rate += criterion.rate;
      }
      sector.total_criterions_answered = criterions_answered;
      sector.total_rate = sector_rate/sector.total_criterions;
      evaluation_rate += sector.total_rate;
      evaluation_criterions_answered += sector.total_criterions_answered;
    }
    updated_evaluation.total_rate = evaluation_rate/updated_evaluation.content.length;
    updated_evaluation.total_criterions_answered = evaluation_criterions_answered;
    setEvaluation(updated_evaluation);
  }

  function getQuestionComponent(question){
    switch (question.type_question) {
      case 1:
        return  <Rate
          style={{fontSize: 30}}
          defaultValue={question.rate}
          onChange={(e) => {handleChange(e, question)}}
          disabled={false}
          character={<FontAwesomeIcon icon = {faStar} size = { "sm" } /> }
                />
      case 2:
        return <Form.Control as="select"
                defaultValue={question.rate}
                onChange={(e) => {handleChange(e, question)}}
               >
          <option>Selecione uma opção</option>
          <option value={0}>Não</option>
          <option value={2.5}>Talvez</option>
          <option value={5}>Sim</option>
        </Form.Control>
      case 3:
        return <Form inline>
          <Form.Check
            type="switch"
            custom="true"
            id={question.id}
            label={ (question.rate === 0 || question.rate === null) ? "Não":"Sim"}
            defaultChecked={question.rate >0}
            onChange={(e) => {handleChange(e, question)}}
          />
        </Form>
      default:
        break
    }
  }

  function sectorsList(){
    let sector = updated_evaluation.content.find(e=>e.id === Number(params.id));
    if (!sector) {
      return ""
    }
    return sector.type_sector.criterions.map((criterion, index)=>(
      <Card key={index}>
        <Accordion.Toggle as={Card.Header} className = "btn-primary d-flex justify-content-between align-items-between" onClick = {()=>(e === index+1?setE(''):setE(index+1))} >
          {criterion.name}
          <span className = "pl-4">
            {index+1 === e?
            <FontAwesomeIcon icon = {faChevronUp} size = { "lg" } />
            :
            <FontAwesomeIcon icon = {faChevronDown} size = { "lg" } />
            }
          </span>
          <Badge pill variant="info" className="float-right">
            Perguntas Respondidas: {criterion.questions_answered}/{criterion.questions.length}
          </Badge>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={index+1}>
          <Card.Body>
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Pergunta</th>
                  <th>Tipo</th>
                  <th>Resposta</th>
                </tr>
              </thead>
              <tbody>
                {criterion.questions.map((question, question_index) => (
                  <tr key = {question_index}>
                    <td>{question.sequence}</td>
                    <td>{question.question}</td>
                    <td>{question.type_question}</td>
                    <td>{getQuestionComponent(question)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    ))
  }

  return (
    <>
      <Accordion activeKey = {e} >
        { sectorsList() }
      </Accordion>
    </>
  )
}

export default CriterionsNewEvaluation;
