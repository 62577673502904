import React, {useState, useEffect} from "react";
import PageHeader from "../../page_header/PageHeader";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonsNewEvaluation from "./buttons";
import CriterionsNewEvaluation from "./criterionsNewEvaluation";
import api from "../../../services/api";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPencilAlt, faTrashAlt, faCheckCircle, faBan } from '@fortawesome/free-solid-svg-icons'

library.add(faPencilAlt, faTrashAlt, faCheckCircle, faBan);

function NewEvaluation(props){
  const [ubs, setUbs] = useState({});
  const [sector, setSector] = useState({});
  const [evaluation, setEvaluation] = useState(null);
  const {match: {params}} = props;

  async function getUbs(id) {
    try {
      let response = await api.get(`/ubs/${id}`);
      setUbs(response.data);
    }catch {
      alert('Dados da UBS não encontrados!');
    }
  }

  async function getSector(id) {
    try {
      let response = await api.get(`/sectors/`+id);
      setSector(response.data);

    }catch {
      alert('Dados do Setor não encontrados!');
    }
  }

  useEffect(() => {
    getSector(params.id);
    let e = JSON.parse(localStorage.getItem('evaluation'));
    if (e) {
      setEvaluation(e)
      getUbs(e.ub_id);
    }
  },[params.id])

  return (
    evaluation?
    <>
      <PageHeader>
        <Link to= "/" className = "breadcrumb-item"> Home </Link>
        <Link to= {"/nova_avaliacao"} className = "breadcrumb-item"> {ubs.name || <Skeleton />} </Link>
        <Link to= {"/avaliacoes/"+ubs.id} className = "breadcrumb-item">Painel da avaliação </Link>
        <Breadcrumb.Item active>Avaliar</Breadcrumb.Item>
      </PageHeader>
      <Card>
        <Card.Body>
          <Card.Title> Avaliação - {ubs.name || <Skeleton />} - {(sector && sector.type_sector && sector.type_sector.name) || <Skeleton />}</Card.Title>
          <Row>
            <Col md= { 12 }>
              <CriterionsNewEvaluation
                evaluation = {evaluation}
                setEvaluation = {setEvaluation}
              />
          </Col>
        </Row>
        <Row className = "mt-3">
          <Col md= { 12 }>
            <ButtonsNewEvaluation
              ubs = {ubs}
              sector = {sector}
              type_sector = {sector.type_sector}
              evaluation = {evaluation}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
    </>
  :
    <h4>Avaliação não encontrada</h4>
  )
}

export default NewEvaluation;
