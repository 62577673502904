import axios from "axios";
import { getToken } from "../context/AuthContext";
import  MyToast from "../services/toast";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
}, (error) => {
    if (error.request.status === 401) {
        const requestConfig = error.config;
        MyToast.errorToast('Sua sessão expirou, faça login novamente!',60000, "top-right");
        return axios(requestConfig);
    }
    if (error.request.status === 500) {
        MyToast.errorToast('Erro ao conectar com o servidor!',5000, "top-right");
    }
    // I can handle a request with errors here
    return Promise.reject(error);
});

// Add a response interceptor
api.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response){
       if (error.response.status === 408 || error.code === 'ECONNABORTED') {
           MyToast.errorToast('O servidor não está respondendo!',60000, "top-right");
       }
       if (error.response.status === 401) {
           MyToast.errorToast('Sua sessão expirou, faça login novamente!',60000, "top-right");
           window.location.reload();
       }
       if (error.response.status === 500) {
           MyToast.errorToast('Ocorreu erro no servidor!',5000, "top-right");
       }
    }

    return Promise.reject(error);
  });

export default api;
