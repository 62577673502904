
import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import api from "../../../services/api";
import  MyToast from "../../../services/toast";

function CreateCriterions(props) {
  const [isValidated, setIsValidated] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type_sectors, setTypeSectors] = useState([]);
  const [active, setActive] = useState(true);
  const [type_sector_id, setTypeSectorId] = useState(null);
  const [show, setShow] = useState('');

  function clearForm(){
    setName('');
    setDescription('');
    setActive(true);
    setTypeSectorId(null);
    setIsValidated(false);
  }

  const handleSubmit = (async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setIsValidated(true);
    }else {
      var criterion =  {
          criterion: { name: name,
              description: description,
              active: active,
              type_sector_id: type_sector_id
          }
      };
      await api.post(`/criterions`, criterion)
      .then((response) => {
        setShow(false);
          MyToast.successToast('Critério '+ criterion.criterion.name +' cadastrado com sucesso',5000, "top-center");
        clearForm();
        props.fetchCriterions(props.pageIndex, props.pageSize, props.searchTerm);
      }).catch((error) => {
          MyToast.errorToast('Erro ao cadastrar o critério: '+ error.message,5000, "top-center");
        setIsValidated(true);
      })
    }
  });

  const getTypeSectors = (async ()=>{
      let response = await api.get(`/type_sectors`);
      setTypeSectors(response.data);
  });
  useEffect(() => {
      getTypeSectors();
  },[]);

  const handleChange = () => {
      setActive(!active)
  }
  return (
    <>
        <Button onClick={e => setShow(true)} variant="primary" className="float-right create_task_btn">Cadastrar novo Critério</Button>

        <Modal show={show || false} onHide={e => {setShow(false);clearForm();}}>
            <Modal.Header closeButton>
                <Modal.Title>Novo Critério</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form id = "form_criterions" noValidate validated = {isValidated} onSubmit = {handleSubmit}>
              <Form.Group>
                <Form.Control
                  required
                  type="text"
                  placeholder="Critério..."
                  defaultValue={name || ''}
                  onChange={e => setName(e.target.value)}
                  maxLength="50"
                  minLength="3"
                />
                  <Form.Text className="text-muted">
                      Escreva o nome do critério de no mínimo 3 e máximo 50 caracteres.
                  </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Control
                   required
                   type="text"
                   placeholder="Descrição do criterio..."
                   defaultValue={description || ''}
                   onChange={e => setDescription(e.target.value)}
                   maxLength="200"
                   minLength="10"
                />
                  <Form.Text className="text-muted">
                     Descreva o que o critério aborda, mínimo 10 e máximo 200 caracteres.
                  </Form.Text>
              </Form.Group>
              <Form.Group controlId="formSelectTypeSector">
                <Form.Label>Tipo de Setor</Form.Label>
                <Select
                  required
                  placeholder = "Selecione um tipo de setor"
                  onChange={e => setTypeSectorId(e.value)}
                  options = {type_sectors.map((typesec, index) => {
                    return { key: typesec.id, value: typesec.id, label: typesec.name}
                  })}
                />
              </Form.Group>
              <Form.Check
                label="Ativo?"
                value={active}
                checked={active}
                onChange={handleChange}
              />
              <Form.Group className="mt-1">
                <Button variant="secondary" onClick={e => {setShow(false); clearForm()}}>
                Cancelar
                </Button>
                <Button variant="success" type="submit">
                Salvar
                </Button>
              </Form.Group>
            </Form>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </>
  );
}

export default CreateCriterions;
