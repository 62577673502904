import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import api from "../../services/api";
import List from './List';
import CreateTypeSectors from './CreateTypeSectors';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import PageHeader from "../page_header/PageHeader";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';

library.add(faPencilAlt, faTrashAlt)
function TypeSectors(props) {
  const [type_sectors, setTypeSectors] = useState([]);
  const [total, setTotal] = useState(0);
  const [total_filtered, setTotalFiltered] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = React.useRef(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");


    //chama endpoint task do rails : GET
  const fetchTypeSectors = useCallback(
    ({pageSize, pageIndex, searchTerm})=> {
      const fetchID = ++fetchIdRef.current
      setLoading(true);
      if (fetchID === fetchIdRef.current) {
        let expression = `/type_sectors?page=${pageIndex+1}&page_size=${pageSize}`;
        if (typeof(searchTerm) !== 'undefined') {
          expression = expression.concat(`&search=${searchTerm}`)
        }
        api.get(expression)
        .then(function(response){
          setTypeSectors(response.data);
          setTotal(response.headers["x-total-registries"]);
          setTotalFiltered(response.headers["x-total-filtered"]);
          setPageCount(Math.ceil(response.headers["x-total-filtered"]/pageSize));
          setLoading(false);
        })
        .catch(function(error){
          console.error(error);
        })
      }
    },[]
  )

  function handleChange(e){
    e.preventDefault();
    let target = e.target
    setSearchTerm(target.value)
  }

  function handleSearch(e){
    e.preventDefault();
    fetchTypeSectors({pageIndex, pageSize, searchTerm});
  }

  return (
    <>
      <PageHeader>
        <Link to= "/" className = "breadcrumb-item"> Home </Link>
        <Breadcrumb.Item active>Tipos de setores</Breadcrumb.Item>
      </PageHeader>
      <Card className = "mb-3">
        <Card.Body>
          <Card.Title>Tipos de setores </Card.Title>
          <List
            fetchTypeSectors={fetchTypeSectors}
            typeSectors={type_sectors}
            total = {total}
            total_filtered = { total_filtered }
            loading = {loading}
            pageCount = {pageCount}
            pageIndex = {pageIndex}
            setPageIndex = {setPageIndex}
            pageSize = {pageSize}
            setPageSize = { setPageSize }
            searchTerm = {searchTerm}
            handleChange = {handleChange}
            handleSearch = {handleSearch}
          />
          <CreateTypeSectors
            fetchTypeSectors={fetchTypeSectors}
            pageIndex = {pageIndex}
            pageSize = {pageSize}
            searchTerm = { searchTerm }
          />
        </Card.Body>
      </Card>
    </>
  );

}

export default TypeSectors;
