import React, {useState, useEffect, useCallback} from "react";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import AddSectors from './AddSectors';
import EditSectors from './EditSectors';
import api from "../../services/api";

function UBSRowActions(props){
  const [sectors, setSectors] = useState([]);

  const fetchSectors = useCallback(()=>{
    let id = props.ubs.id;
    api.get(`/ubs/${id}/sectors`)
    .then(function(result){
      setSectors(result.data);
    }).catch(function(error){

    });
  },[props.ubs.id]);

    useEffect(()=>{
      fetchSectors();
    },[fetchSectors]);

  return(
    <ButtonGroup>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id='add_sector_btn_tooltip'>
            Adicionar Setores
          </Tooltip>
        }
      >
        <AddSectors ubs = {props.ubs} type_sectors = {props.type_sectors} sectors = {sectors} fetchSectors = {fetchSectors} />

      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id='add_sector_btn_tooltip'>
            Editar Setores
          </Tooltip>
        }
      >
        <EditSectors ubs = {props.ubs} type_sectors = {props.type_sectors} sectors = {sectors} fetchSectors = {fetchSectors}/>
      </OverlayTrigger>
    </ButtonGroup>
  )
}
export default UBSRowActions;
