import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../../services/api";
import PageHeader from "../../page_header/PageHeader";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import dateFormat from 'dateformat';
import StatusEvaluation from "../dashboard/statusEvaluation";
import TableDashBoardEvaluation from "../dashboard/table";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Rate from 'rc-rate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

function ShowEvaluation(props){

  const [evaluation, setEvaluation] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selected_sector, setSelectedSector] = useState();
  const [show, setShow] = useState(false);
  const [e, setE] = React.useState(0);
  const params = useParams();

  async function fetchEvaluation(id){
    await api.get(`/evaluations/${id}`)
    .then((response) => {
      setEvaluation(response.data);
      setIsLoading(false)
    }).catch((error) => {
      console.log(error);
      console.error(error);
    })
  }

  useEffect(() => {
    fetchEvaluation(params.id)
  }, [params.id])

  function visualiseSector(sector_id){
    setSelectedSector(sector_id);
    setShow(true);
  }

  return(
    isLoading?
     ""
     :
    <>
      <PageHeader>
        <Link to= "/" className = "breadcrumb-item"> Home </Link>
        <Link to= {"/historico/"} className = "breadcrumb-item"> Histórico </Link>
        <Breadcrumb.Item active>Avaliação</Breadcrumb.Item>
      </PageHeader>
      <Card>
        <Card.Body>
          <Card.Title>Avaliação {evaluation.ub.name} em {dateFormat(evaluation.updated_at,'dd/mm/yyyy')}</Card.Title>
          <Row>
            <StatusEvaluation evaluation={evaluation}/>
          </Row>
          <Row className = "mt-3">
            <Col lg={12}>
              <TableDashBoardEvaluation
                evaluation={evaluation}
                visualiseSector = {visualiseSector}
              />
            </Col>
          </Row>
          <Row>
            <Link to = "/historico" className = "btn btn-primary">Voltar</Link>
          </Row>
        </Card.Body>
      </Card>

      <QuestionsModal
        evaluation = {evaluation}
        sector_id = {selected_sector}
        show = {show}
        setShow = {setShow  }
        e = {e}
        setE = {setE}
      />
    </>
  )
}

export default ShowEvaluation;

function QuestionsModal({evaluation, sector_id, show, setShow, e, setE}){

  const handleClose = () => setShow(false);
  const sector = evaluation.content.find(e=>e.id === Number(sector_id));
  function sectorsList(){
    if (!sector) {
      return ""
    }
    return sector.type_sector.criterions.map((criterion, index)=>(
      <Card key={index}>
      <Accordion.Toggle as={Card.Header} className = "btn-primary d-flex justify-content-between align-items-between" eventKey={index} onClick = {()=>(e === index?setE(''):setE(index))} >
        {criterion.name}
        <span className = "pl-4">
          {index === e?
          <FontAwesomeIcon icon = {faChevronUp} size = { "lg" } />
          :
          <FontAwesomeIcon icon = {faChevronDown} size = { "lg" } />
          }
        </span>
        <Badge pill variant="info" className="float-right">
          Perguntas Respondidas: {criterion.questions_answered}/{criterion.questions.length}
        </Badge>
      </Accordion.Toggle>
        <Accordion.Collapse eventKey={index}>
          <Card.Body>
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Pergunta</th>
                  <th>Tipo</th>
                  <th>Resposta</th>
                </tr>
              </thead>
              <tbody>
                {criterion.questions.map((question, question_index) => (
                  <tr key = {question_index}>
                    <td>{question.sequence}</td>
                    <td>{question.question}</td>
                    <td>{question.type_question}</td>
                    <td>{getQuestionComponent(question)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    ))
  }

  function getQuestionComponent(question){
    switch (question.type_question) {
      case 1:
        return  <Rate
          style={{fontSize: 30}}
          defaultValue={question.rate}
          disabled={true}
          character={<FontAwesomeIcon icon = {faStar} size = { "sm" } /> }
                />
      case 2:
        return <Form.Control as="select"
          defaultValue={question.rate}
          disabled
               >
          <option>Selecione uma opção</option>
          <option value={0}>Não</option>
          <option value={2.5}>Talvez</option>
          <option value={5}>Sim</option>

          </Form.Control>
      case 3:
        return <Form inline>
          <Form.Check
            type="switch"
            custom="true"
            id={question.id}
            label={ (question.rate === 0 || question.rate === null) ? "Não":"Sim" }
            defaultChecked={question.rate >0}
            disabled = {true}
          />
        </Form>
      default:
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} size = "lg">
        <Modal.Header closeButton>
          <Modal.Title>{sector && sector.type_sector.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion activeKey = {e} >
            { sectorsList() }
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
