import React from "react";
import { Link } from "react-router-dom";

function ButtonsHistory(props) {
  return (
    <>
      <Link className="btn btn-primary float-right" to={"/nova_avaliacao"}>Nova avaliação</Link>
      <Link className="btn btn-secondary float-right" to={"/"}>Voltar</Link>
    </>
  )
}

export default ButtonsHistory;
