import React from "react";
import {slide as Menu} from "react-burger-menu";
import {NavLink, useHistory} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {useAuth} from "../../context/AuthContext";
import {useUser} from "../../context/UserContext";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faPlusCircle,
    faHome,
    faSignOutAlt,
    faHistory,
    faUsers,
    faCheckCircle,
    faMonument,
    faLandmark,
    faQuestionCircle,
    faStickyNote
} from '@fortawesome/free-solid-svg-icons';
import api from "../../services/api";

function Teste({isOpen, handleStateChange, closeMenu}) {
    const auth = useAuth();
    const user = useUser();
    const history = useHistory();
    var styles = {
        bmBurgerButton: {
            position: 'fixed',
            width: '36px',
            height: '30px',
            left: '10px',
            top: '10px',
            "background-color": "white",
            border: "white"
        },
        bmBurgerBars: {
            background: '#373a47'
        },
        bmBurgerBarsHover: {
            background: '#a90000'
        },
        bmCrossButton: {
            height: '24px',
            width: '24px'
        },
        bmCross: {
            background: '#bdc3c7'
        },
        bmMenuWrap: {
            position: 'fixed',
            height: '100%'
        },
        bmMenu: {
            background: '#373a47',
            padding: '2.5em 1.5em 0',
            fontSize: '1.15em'
        },
        bmMorphShape: {
            fill: '#373a47'
        },
        bmItemList: {
            color: '#b8b7ad',
            padding: '0.8em'
        },
        bmItem: {
            color: "white",
            display: "block",
            padding: "10px",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "1.0em",
            textAlign: "left",
            backgroundColor: "transparent",
            border: "0",
        },
        bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)'
        }
    }

    async function logout() {
        try {
            await api.delete(`/logout`);
            auth.logout(history);
        } catch {
        }
    }

    return (
        <Menu
            isOpen={isOpen}
            onStateChange={(state) => handleStateChange(state)}
            styles={styles}
            menuClassName={"nav"}
            itemListClassName={"nav-item"}
            customBurgerIcon={false}
        >
            <NavLink to="/home" activeClassName="active_link" onClick={closeMenu}>
                <FontAwesomeIcon icon={faHome} size={"lg"}/> Início
            </NavLink>
            {user.role === 1 ?
                <NavLink to="/nova_avaliacao" activeClassName="active_link" onClick={closeMenu}>
                    <FontAwesomeIcon icon={faPlusCircle} size={"lg"}/> Nova Avaliação
                </NavLink> :
                ""
            }

            {user.role === 0 ?
                <NavLink to="/tiposDeSetor" activeClassName="active_link" onClick={closeMenu}>
                    <FontAwesomeIcon icon={faMonument} size={"lg"}/> Tipos De Setor
                </NavLink>
                : ""
            }
            {user.role === 0 ?
                <NavLink to="/ubs" activeClassName="active_link" onClick={closeMenu}>
                    <FontAwesomeIcon icon={faLandmark} size={"lg"}/> Setores
                </NavLink>
                : ""
            }
            {user.role === 0 ?
                <NavLink to="/criterios" activeClassName="active_link" onClick={closeMenu}>
                    <FontAwesomeIcon icon={faCheckCircle} size={"lg"}/> Critérios
                </NavLink>
                : ""
            }
            {user.role === 0 ?
                <NavLink to="/questions" activeClassName="active_link" onClick={closeMenu}>
                    <FontAwesomeIcon icon={faQuestionCircle} size={"lg"}/> Perguntas
                </NavLink>
                : ""
            }
            <NavLink to="/historico" activeClassName="active_link" onClick={closeMenu}>
                <FontAwesomeIcon icon={faHistory} size={"lg"}/> Histórico
            </NavLink>
            {user.role === 0 ?
                <NavLink to="/notices" activeClassName="active_link" onClick={closeMenu}>
                    <FontAwesomeIcon icon={faStickyNote} size={"lg"}/> Noticias/Avisos
                </NavLink>
                : ""
            }
            {user.role === 0 ?
                <NavLink to="/usuarios" activeClassName="active_link" onClick={closeMenu}>
                    <FontAwesomeIcon icon={faUsers} size={"lg"}/> Usuários
                </NavLink>
                : ""
            }

            <Button bsPrefix="nav_button" onClick={logout}>
                <FontAwesomeIcon icon={faSignOutAlt} size={"lg"}/> Sair
            </Button>
        </Menu>
    );
}

export default Teste
