
import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import api from "../../services/api";
import  MyToast from "../../services/toast";

function CreateTypeSectors({fetchTypeSectors, pageIndex, pageSize, searchTerm}) {
  const [isValidated, setIsValidated] = useState(false)
  const [name, setName] = useState('');
  const [show, setShow] = useState('');


  const handleSubmit = async event => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else {
      await api.post(
        "/type_sectors",
        {
          type_sector: { name: name, active: true}
        },
        {headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }}
      ).then(function (response){
        MyToast.successToast('Tipo de setor cadastrado com sucesso!',5000, "top-center");
        setShow(false)
        setName('')
        fetchTypeSectors({pageIndex, pageSize, searchTerm});
      }).catch(function (error) {
        MyToast.errorToast('Erro ao cadastrar a tipo de setor: '+ error.message,5000, "top-center");
        console.error(error);
      })
    }
    setIsValidated(true);
  };

  return (
    <>
      <Button onClick={e => setShow(true)} variant="primary" className="float-right create_task_btn">Cadastrar novo Setor</Button>

      <Modal show={show || false} onHide={e => setShow(false)}>
          <Modal.Header closeButton>
              <Modal.Title>Novo Setor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form id = "form_type_sectors" noValidate validated= {isValidated} onSubmit = {handleSubmit}>
              <Form.Group>
                <Form.Control
                  required
                  type="text"
                  placeholder="Nome do setor..."
                  value={name || ''}
                  onChange={e => setName(e.target.value)}
                />
              </Form.Group>
              <Button variant="secondary" onClick={e => setShow(false)}>
                Cancelar
              </Button>
              <Button variant="success" type="submit">
                Salvar
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateTypeSectors;
