import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Navbar from "../navigation/Navbar";
import Sidebar from "../navigation/Sidebar";
import DashBoard from "../dashboard/DashBoard";
import DashBoardEvaluation from "../evaluations/dashboard/DashBoardEvaluation";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import NewEvaluation from "../evaluations/newEvaluation/NewEvaluation";
import ShowEvaluation from "../evaluations/history/ShowEvaluation";
import SelectUbs from "../select_ubs/SelectUbs";
import History from "../evaluations/history/History";
import { AuthContext } from "../../context/AuthContext"

class Home extends React.Component{
  constructor(props){
    super(props);
    this.state={
      isSidebarOpen:false
    }
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  };

  toggleSidebar(){
    this.setState({isSidebarOpen: !this.state.isSidebarOpen});
  };

  handleStateChange (state) {
    console.log(state);
    this.setState({isSidebarOpen: state.isOpen})
  }

  closeMenu(){
    this.setState({isSidebarOpen: false})
  }

  static contextType = AuthContext;

  render(){

    return (
      <Router>
        <div className = "wrapper">
          <Sidebar isOpen = {this.state.isSidebarOpen} handleStateChange = {this.handleStateChange} closeMenu = {this.closeMenu}/>
          <div id = "content">
            <Navbar toggleSidebar = {this.toggleSidebar} />
            <Container fluid = {true}>
              <main>
                <Row className = "p-3">
                </Row>
                <Switch>
                  {/* */}
                  <Route exact path="/home" component = { DashBoard } />
                  {/* */}
                  {this.context.data.user.role ===1?
                    <Route path="/avaliacoes/:ubs" exact component={DashBoardEvaluation} />
                  :
                  ""
                }
                  {/* */}
                  {this.context.data.user.role ===1?
                    <Route path="/nova_avaliacao" exact> <SelectUbs /> </Route>
                  :
                  ""
                }
                  {/* */}
                  <Route path="/historico" exact> <History/> </Route>
                  {/* */}
                  {this.context.data.user.role ===1?
                    <Route path="/newevaluate/:id" exact component={NewEvaluation}/>
                    :
                    ""
                  }
                  {/* */}
                  <Route path="/evaluation/:id" exact component={ ShowEvaluation }/>
                  {/* */}
                  <Route path="*" exact> <Redirect to = "/home"/> </Route>
                </Switch>
              </main>
            </Container>
          </div>
        </div>
      </Router>
        )
    }
}

export default Home;
