import React, { useState, useEffect, useCallback } from "react";
import {ButtonGroup, Table} from "react-bootstrap";
import Card from 'react-bootstrap/Card'
import api from "../../services/api";
import Rate from "rc-rate/es/Rate";
import {Link} from "react-router-dom";
import { useUser } from "../../context/UserContext"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSearch, faStar} from '@fortawesome/free-solid-svg-icons';
import Badge from "react-bootstrap/esm/Badge";

function LastEvaluations(props){
  const user = useUser();
  const [evaluations, setEvaluations] = useState([]);

  const getEvaluation = useCallback ( async ()=>{
    let expression = "";
    switch (user.role) {
      case 0:
        expression = `/evaluations/get_evaluations?count=${5}`
        break;
      case 1:
      case 2:
        expression = `/evaluations/get_evaluations?regional_id=${user.regional_id}&count=${5}`
        break;
      case 3:
        expression = `/evaluations/get_evaluations?regional_id=${user.regional_id}&count=${5}&ub_id=${user.ub_id}`
        break;
      default:
    }
    try {
      let response = await api.get(expression);
      setEvaluations(response.data);
    }catch{}
  },[user])

  useEffect(() => {
    getEvaluation();
  },[getEvaluation])

  return (
    <>
      <Card>
        <Card.Header className = "bg-primary text-white" >
          <strong>5 últimas avaliações</strong>
        </Card.Header>
        <Table striped bordered hover>
          <thead>
          <tr>
            <th>Estabelecimento</th>
            <th>Status avaliação</th>
            <th>Critérios Respondidos</th>
            <th>Nota</th>
            <th>Ações</th>
          </tr>
          </thead>
          <tbody>
          {evaluations && evaluations.length>0?evaluations.map((evaluation, index) => {
            return <tr key = {evaluation.id}>
              <td>{evaluation.ub.name}</td>
              <td>{evaluation.status === 'Concluído'? <Badge pill variant="success">{evaluation.status}</Badge>: evaluation.status === 'Em andamento' ? <Badge pill variant="primary">{evaluation.status}</Badge>: <Badge pill variant="danger">{evaluation.status}</Badge>}</td>
              <td>{evaluation.content ? evaluation.total_criterions_answered : 0}/{evaluation.content ? evaluation.total_criterions : 0}</td>
              <td>{evaluation.content ? parseFloat(evaluation.total_rate).toFixed(2) : 0} <Rate
                defaultValue={evaluation.content ? Number(evaluation.total_rate) : 0}
                style={{fontSize: 16}}
                disabled={true}
                allowHalf
                character={<FontAwesomeIcon icon = {faStar} size = { "sm" } /> }
              />
              </td>
              <td>
                <ButtonGroup aria-label="Basic example">
                {(evaluation.status ==="Em andamento")&&(user.role ===1)?
                <Link to={`/avaliacoes/${evaluation.ub_id}`}
                className="btn btn-primary">
                <FontAwesomeIcon icon = {faSearch} size = { "lg" } />
                </Link>
              :
              <Link to={`/evaluation/${evaluation.id}`}
              className="btn btn-primary">
              <FontAwesomeIcon icon = {faSearch} size = { "lg" } />
              </Link>
            }
                </ButtonGroup>
              </td>
            </tr>
          })
            : <tr><td colSpan={5}>Sem informações</td></tr>
          }
          </tbody>
        </Table>
      </Card>
    </>
  )
}

export default LastEvaluations;
