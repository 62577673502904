import React, { useState, useEffect, useCallback } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import api from "../../../services/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import  MyToast from "../../../services/toast";

function EditUsers (props) {
  const pageIndex = props.pageIndex;
  const pageSize = props.pageSize;
  const fetchUsers = props.fetchUsers;
  const [isValidated, setIsValidated] = useState(false);
  const [name, setName] = useState("");
  const [registration, setRegistration] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(null);
  const [password_confirmation, setPassword_confirmation] = useState(null);
  const [active, setActive] = useState(false);
  const [regional_id, setRegionalId] = useState(null);
  const [establishment_id, setEstablishmentId] = useState(null);
  const [show, setShow] = useState(false);
  const [establishment_list, setEstablishmentList] = useState(props.establishments);

  const resetForm = useCallback(() => {
      setName(props.user.name);
      setRegistration(props.user.registration);
      setRole(props.user.role);
      setEmail(props.user.email);
      setPassword(null);
      setPassword_confirmation(null);
      setActive(props.user.active);
      setRegionalId(props.user.regional_id);
      setEstablishmentId(props.user.establishment_id);
      setIsValidated(false);
      setShow(false);
  },[props.user.name, props.user.registration, props.user.role, props.user.email, props.user.active, props.user.regional_id, props.user.establishment_id]);

  useEffect(() => {
    resetForm()
  },[resetForm]);

  const handleSubmit = (async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    var usu;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }else {
      if (password != null && password_confirmation != null) {
        usu = {
          user: {
              name: name || props.user.name,
              active: active,
              registration: registration || props.user.registration,
              role: role,
              email: email,
              regional_id: regional_id,
              ub_id: establishment_id,
              password_confirmation: password_confirmation,
              password: password
          }
        };
      } else {
        usu = {
            user: {
                name: name || props.user.name,
                active: active,
                registration: registration || props.user.registration,
                role: role,
                email: email,
                regional_id: regional_id,
                ub_id: establishment_id
            }
        };
      }
      await api.put(`/users/${props.user.id}`, usu)
      .then((response) => {
        MyToast.infoToast('Usuário editado com sucesso',5000, "top-center");
        fetchUsers({pageIndex, pageSize})
        setShow(false);
      }).catch((error) => {
        MyToast.errorToast('Erro ao editar o usuário: '+ error.message,5000, "top-center");
        console.error(error);
      });
    }
    setIsValidated(true);
  });

  const getFunctionary = (async (registration)=> {
      if (registration !== '' && registration){
          let response = await api.get(`/functionaries/` + registration);
          setRegistration(registration);
          if (response.data[0]) {
              setName(response.data[0].people.name);
          }
      }else{
          setRegistration(registration);
          setName('');
      }
  });

  const getEstablishmentFilter = ((e)=>{
    let selected_regional_id = Number(e.target.value);
    setRegionalId(selected_regional_id);
    if (Number.isNaN(selected_regional_id)) {
      setEstablishmentList(props.establishments.slice());
    }else {
      setEstablishmentList(props.establishments.filter((ubs) => {
        return ubs.regional_id === selected_regional_id
      }))
    }
  });

  const handleChange = () => {
      setActive(!active)
  };

  function handleSelectRole(e){
    setRole(e.target.value||null)
    setRegionalId('');
    setEstablishmentId('');
  }

  return (
    <>
      <Button variant = "warning" size="sm">
        <FontAwesomeIcon icon="pencil-alt" onClick={e => setShow(true)} size="lg"/>
      </Button>
      <Modal show={show || false} onHide={e => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form id = "user_form" noValidate validated={isValidated} onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Control
            required
            pattern = "\d\d\d\d\d"
            type="text"
            placeholder="Matricula..."
            defaultValue={registration}
            onChange={e => getFunctionary(e.target.value)}
          />
          <Form.Control.Feedback>certo!!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Por favor, preencha a matrícula do usuário ( 5 digitos).
          </Form.Control.Feedback>
        </Form.Group>
          <Form.Group>
            <Form.Control
              required
              type="text"
              placeholder="Nome..."
              defaultValue={name}
              onChange={e => setName(e.target.value)}
            />
            <Form.Control.Feedback>certo!!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor, informe o nome do usuário.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Control
              required
              type="email"
              placeholder="Email..."
              defaultValue={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Form.Control.Feedback>certo!!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor, informe o email do usuário.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Control
              required
              as = "select"
              className="custom-select"
              defaultValue={ Number(role)}
              onChange={handleSelectRole}
            >
              <option value = ''>Selecione o papel no sistema</option>
              <option value={0}>Administrador</option>
              <option value={1}>Avaliador</option>
              <option value={2}>Coordenador</option>
              <option value={3}>Visualizador</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Control
              as = "select"
              className="custom-select"
              disabled = {!role || role ==="0"}
              required = {role && role !== "0"}
              defaultValue={regional_id }
              onChange={getEstablishmentFilter}
            >
              <option value = ''  >Selecione a regional</option>
              {props.regionals.map((regional, index) => {
                return <option key = {regional.id} value={regional.id}>{regional.name}</option>
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Control
              as = "select"
              className="custom-select"
              disabled = {!regional_id || !(role === '3')}
              required = {role === '3'}
              value={establishment_id }
              onChange={e => setEstablishmentId(e.target.value || null)}
            >
              <option value = ''>Selecione o estabelecimento</option>
              {establishment_list.map((establishment, index) => {
                return <option key = {establishment.id} value={establishment.id}>{establishment.name}</option>
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Check label="Ativo?"
              value={active}
              checked={active}
              onChange={handleChange}
            />
          </Form.Group>
            <Form.Group>
                <Form.Control
                    required
                    title="A senha tem que conter 6 caracteres e letras minusculas, maiusculas e números."
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                    type="password"
                    placeholder="Senha..."
                    defaultValue={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <Form.Text className="text-muted">
                    A senha tem que conter no mínimo 6 caracteres e letras minusculas, maiusculas e números.
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Control
                    required
                    title="Confirme a senha definida acima."
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                    type="password"
                    placeholder="Confirmar senha..."
                    defaultValue={password_confirmation}
                    onChange={e => setPassword_confirmation(e.target.value)}
                />
                <Form.Text className="text-muted">
                    Confirme a senha definida acima.
                </Form.Text>
            </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={resetForm}>
          Cancelar
        </Button>
        <Button form = "user_form" variant="success" type="submit">
          Salvar
        </Button>
      </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditUsers;
