// TODO: Filter by type_sector
// TODO: put get sectors and get criterions here (remove from edit and create)
import React, { useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import api from "../../services/api";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import PageHeader from "../page_header/PageHeader";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import CreateQuestions from './create_questions/CreateQuestions';
import EditQuestions from './edit_questions/EditQuestions'
import MyTable from '../table/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPencilAlt, faTrashAlt, faCheckCircle, faBan, faSortNumericUp, faArrowsAltV } from '@fortawesome/free-solid-svg-icons'
import ReorderQuestions from "./reorder_questions/ReorderQuestions";

library.add(faPencilAlt, faTrashAlt, faCheckCircle, faBan, faSortNumericUp, faArrowsAltV)
function Questions(props){

  const [questions, setQuestions] = useState([]);
  const [total, setTotal] = useState(0);
  const [total_filtered, setTotalFiltered] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

    //chama endpoint task do rails : GET
    const fetchQuestions = useCallback(({pageIndex, pageSize, searchTerm}) => {
      setLoading(true);
      let expression = `/questions?page=${pageIndex+1}&page_size=${pageSize}`;
      if (typeof(searchTerm) !== 'undefined') {
        expression = expression.concat(`&search=${searchTerm}`)
      }
      api.get(expression)
      .then((response) => {
        setQuestions(response.data)
        setTotal(response.headers['x-total-registries']);
        setTotalFiltered(response.headers['x-total-filtered']);
        setPageCount(Math.ceil(response.headers['x-total-filtered']/pageSize));
        setLoading(false);
      }).catch((error) => {
          console.error(error);
      })
    },[]);

    const columns = useMemo(() => [
      {
        Header: "#",
        accessor: "sequence"
      }, {
        Header: "Nome",
        accessor: "question"
      },{
        Header: "Ativo?",
        accessor: "active",
        Cell: ({row}) => {
          return row.original.active? "Sim":"Não"
        }
      },{
        Header: "Tipo",
        accessor: "type_question",
        Cell: ({row}) => {
          switch (row.original.type_question){
            case 1:
              return '1 a 5'
            case 2:
              return 'Opções'
            case 3:
              return 'Sim ou Não'
            default:
            console.log(row.original);
              return ''
          }
        }
      },{
        Header: "Critério",
        accessor: "criterion.name"
      },{
        Header: "Tipo de Setor",
        accessor: "criterion.type_sector.name"
      },{
        Header: "Ações",
        Cell: ({row}) => (
          <QuestionActions
            question = { row.original }
            fetchQuestions = { fetchQuestions }
            pageIndex = { pageIndex }
            pageSize = { pageSize }
          />
        )
      }
    ],[fetchQuestions, pageSize, pageIndex]);

    const handleSearch = useCallback((e) => {
      e.preventDefault();
      fetchQuestions({pageIndex, pageSize, searchTerm})
    },[fetchQuestions, pageIndex, pageSize, searchTerm] )

    function handleChange(e){
      e.preventDefault();
      let target = e.target;
      setSearchTerm(target.value)
    }

    return (
      <>
        <PageHeader>
          <Link to= "/" className = "breadcrumb-item"> Home </Link>
          <Breadcrumb.Item active>Perguntas</Breadcrumb.Item>
        </PageHeader>
        <Card>
          <Card.Body>
            <Card.Title>Perguntas</Card.Title>
            <MyTable
              columns = {columns}
              data = {questions}
              fetchData = {fetchQuestions}
              pageCount = {pageCount}
              total = {total}
              total_filtered = { total_filtered }
              search = {{ placeholder: "Nome",
                handleChange: handleChange,
                handleClick: handleSearch
              }}
              searchTerm = { searchTerm }
              loading = {loading}
              pageIndex = {pageIndex}
              pageSize = {pageSize}
              setPageIndex = {setPageIndex}
              setPageSize = {setPageSize}
            />
            <CreateQuestions
              fetchQuestions={fetchQuestions}
              pageIndex = { pageIndex }
              pageSize = { pageSize }
              searchTerm = { searchTerm }
            />
          </Card.Body>
        </Card>
        {/* <Row>
          <div className="col-md-12 mx-auto ">
          <h2 className="title">Perguntas</h2>
          <select className="custom-select" onChange={e => this.setTypeSectorId(e.target.value)}>
          <option defaultValue = "Selecione o tipo de setor"></option>
          {this.state.type_sectors.map((typesec, index) => {
          return <option key = { typesec.id } value={typesec.id}>{typesec.name}</option>
          })}
          </select>
          <List loadquestions={this.loadquestions} questions={this.state.questions}/>
          <CreateQuestions loadquestions={this.loadquestions}/>
          </div>
        </Row> */}
      </>
    );
}

function QuestionActions(props){
  const pageIndex = props.pageIndex;
  const pageSize = props.pageSize;
  const searchTerm = props.searchTerm;
  const fetchQuestions = props.fetchQuestions;
  const question = props.question

  const deleteQuestion = useCallback(() => {
    api.delete(`/questions/${question.id}`)
    .then((response) => {
      fetchQuestions({pageIndex, pageSize, searchTerm})
    }).catch((error) => {
      console.error(error);
    })
  },[fetchQuestions, pageIndex, pageSize, searchTerm, question])

  return(
    <ButtonGroup className="mr-2">
      <ReorderQuestions
          criterion_id={question.criterion_id}
          fetchQuestions={fetchQuestions}
          pageIndex = { pageIndex }
          pageSize = { pageSize }
          searchTerm = { searchTerm }
      />
      <EditQuestions
        question={props.question}
        fetchQuestions={fetchQuestions}
        pageIndex = { pageIndex }
        pageSize = { pageSize }
        searchTerm = { searchTerm }
      />
      <Button variant = "danger" onClick={deleteQuestion} size = "sm">
        <FontAwesomeIcon icon="trash-alt" size="lg"/>
      </Button>
    </ButtonGroup>
  )
}

export default Questions;
