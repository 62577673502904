import React from "react";
import { useUser } from "./context/UserContext";
import Home from "./components/home/Home";
import Admin from  "./components/admin/Admin";
import Login from "./components/login/Login";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  const user = useUser();
  return (
     <> <ToastContainer />
     {user? (user.role === 0? <Admin/>:<Home/>): <Login/>} </>
  );
}

export default App;
